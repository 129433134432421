import React, { useState } from 'react';

import Button from '../../UI/button/Button';

import cl from './styles/SignUpContainer.module.scss';
import api from '../../../services/api';

const SignUpSuccess = ({ t }) => {
    const [resetSignUpSent, setResetSignUpSent] = useState(false);

    const resetSignUp = async () => {
        await api.auth
            .resetSignUpEmail({
                // password, email, token
            })
            .then((data) => {
                setResetSignUpSent(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className={cl.successBlock}>
            <div className={cl.top}>
                <h1 className={cl.title}>{t('sign_up.success_title')}</h1>
                <p className={cl.text}>{t('sign_up.success_text')}</p>
                <div className={cl.btnWr}>
                    <Button type="router-link" href="/login">
                        {t('sign_up.success_button_text')}
                    </Button>
                </div>
            </div>
            <div className={cl.footer}>
                <p className={cl.reset} onClick={resetSignUp}>
                    {resetSignUpSent ? (
                        <span>{t('sign_up.email_resent')}</span>
                    ) : (
                        <>
                            {t('sign_up.success_reset_text')}
                            <span>{t('sign_up.success_reset_button')}</span>
                        </>
                    )}
                </p>
                <p className={cl.message}>
                    {t('sign_up.success_footer_rext')}
                    <a href="mailto:notification@recrouthr.com">
                        notification@recrouthr.com
                    </a>
                </p>
            </div>
        </div>
    );
};

export default SignUpSuccess;
