import { useState, useEffect } from "react";

import { BreakpointsContext } from "../../contexts/breakpoints-context";

import { useMediaQuery } from "react-responsive";

function BreakpointsProvider(props) {
  const [breakpoint, setBreakpoint] = useState(null);

  const mob = useMediaQuery({ query: "(max-width: 767px)" });
  const tabl = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const desk = useMediaQuery({ query: "(min-width: 1025px)" });

  useEffect(() => {
    if (mob) return setBreakpoint("mob");
    if (tabl) return setBreakpoint("tab");
    if (desk) return setBreakpoint("desk");
  }, [mob, tabl, desk]);

  return (
    <BreakpointsContext.Provider value={breakpoint}>
      {props.children}
    </BreakpointsContext.Provider>
  );
}

export default BreakpointsProvider;
