import axios from '../axios';
import urlSearchParams from '../../../utils/urlSearchParams';

const endpoints = {
    registration: data => axios.post('/register', urlSearchParams(data)),

    login: data => axios.post('/login', urlSearchParams(data)),

    verifyCode: data => axios.post('/verify-code', data),

    forgotPassword: data => axios.post('/forgot', urlSearchParams(data)),

    setNewPassword: data => axios.post('/setNewPasswordFromToken', urlSearchParams(data)),

    activateAccount: data => axios.post('/activate-account', urlSearchParams(data)),

    resetSignUpEmail: data => axios.post('/reset-signup-email', urlSearchParams(data)),

    // company data --------------------------------------------------------
    getUsersCompany: () => axios.get('/getUsersCompany'),

    getCompanyById: data => axios.post('/getCompanyByID', urlSearchParams(data)),

    updateUsersCompany: data => axios.post('/updateUsersCompany', data),

    getUserProfile: () => axios.get('/getUserProfile'),

    updateUserProfile: data => axios.post('/updateUserProfile', data),

    // countries and industries --------------------------------------------
    getCountriesList: () => axios.get('/datasets/countries'),

    getIndustriesList: () => axios.get('/datasets/industries'),

    getLanguages: () => axios.get('/datasets/languages'),

    getDegrees: () => axios.get('/datasets/degrees'),

    getRoles: () => axios.get('/datasets/roles'),

    getSkills: () => axios.get('/datasets/skills'),

    // presentation data ---------------------------------------------------
    getUsersCompanyPresentation: () => axios.get('/getUsersCompanyPresentation'),

    getPresentationSections: () => axios.get('/getUsersCompanyPresentationWithSections'),

    getPresentationSectionsById: data => axios.post('/getPresentationSections', urlSearchParams(data)),

    getPresentationById: data => axios.post('/getPresentationByID', urlSearchParams(data)),

    getPresentationSectionByID: data => axios.post('/getPresentationSectionByID', urlSearchParams(data)),

    getPresentationByCompanyID: data => axios.post('/getPresentationByCompanyID', urlSearchParams(data)),

    createPresentationSection: data => axios.post('/createPresentationSection', data),

    updatePresentationSection: data => axios.post('/updatePresentationSection', urlSearchParams(data)),

    removePresentationSection: data => axios.post('/removePresentationSection', data),

    updateUsersCompanyPresentation: data => axios.post('/updateUsersCompanyPresentation', urlSearchParams(data)),

    getPresentationByLinkTag: data => axios.post('/getPresentationByLinkTag', urlSearchParams(data)),

    incrementPresentationShares: data => axios.post('/incrementPresentationShares', urlSearchParams(data)),

    incrementPresentationVisits: data => axios.post('/incrementPresentationVisits', urlSearchParams(data)),

    addPresentationRating: data => axios.post('/addPresentationRating', urlSearchParams(data)),

    // stripe endpoints ---------------------------------------------------
    createCheckoutSession: data => axios.post('/stripe/create-checkout-session', data),
    getPrices: () => axios.get('/stripe/get-prices'),
    getCompanySubscriptionList: () => axios.get('/stripe/company-subscription-list'),
    getInvoiceOfSubscription: data => axios.post('/stripe/invoice-of-subscription', data),
    getInvoiceOfPayment: data => axios.post('/stripe/invoice-of-payment', data),
    cancelSubscription: data => axios.post('/stripe/cancel-subscription', data),

    // notifications endpoints ---------------------------------------------------
    getNotifications: () => axios.get('/notifications/getAll'),
    updateUserNotificationsSettings: data => axios.post('/updateUserNotificationsSettings', data),
    getUserNotificationsSettings: () => axios.get('/getUserNotificationsSettings'),

    // company users endpoints ---------------------------------------------------

    getCompanyUsers: () => axios.get('/getCompanyUsers'),
    insertCompanyUser: data => axios.post('insertCompanyUser', data),
    updateCompanyUser: data => axios.post('updateCompanyUser', data),
    removeCompanyUser: data => axios.post('removeCompanyUser', data),
};

export default endpoints;
