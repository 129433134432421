import React from 'react';
import {useTranslation} from 'react-i18next';
import api from '../../../../services/api';

import useModal from '../../../../hooks/use-modal';
import ShareVideoModal from './ShareVideoModal';

import cl from './styles/SlideControlBox.module.scss';
import useAuth from '../../../../hooks/use-auth';

const SlideControlBox = ({id}) => {
    const {show, setModalContextData, hide} = useModal();
    const {t} = useTranslation();

    const auth = useAuth();

    console.log(auth.presentation);

    const handleInviteCoworker = async () => {
        setModalContextData({
            isOpen: true,
            context: <ShareVideoModal closeModal={hide} t={t} />,
        });
    };

    const shareHandler = e => {
        e.stopPropagation();
        console.log('Share');
        handleInviteCoworker();
    };
    const deleteHandler = async e => {
        e.stopPropagation();
        await api.auth.removePresentationSection({id: id}).then(res => {
            window.location.reload();
        });
    };

    return (
        <div className={cl.slideControlBox}>
            <div className={cl.btns}>
                <button className={cl.btnShare} onClick={shareHandler}>
                    <span className="icon-share"></span>
                </button>
                <button className={cl.btnDelete} onClick={deleteHandler}>
                    <span className="icon-delete"></span>
                </button>
            </div>
        </div>
    );
};

export default SlideControlBox;
