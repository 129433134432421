import React, { useState } from "react";
import { Link } from "react-router-dom";

import SignUpForm from "./SignUpForm";
import SignUpSuccess from "./SignUpSuccess";

import { ReactComponent as Logo } from "../../../images/capa-hr-logo.svg";
import brandImg from "../../../images/brand_image-sign-up.png";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/SignUpContainer.module.scss";

const SignUpBlock = () => {
  const { t, i18n } = useTranslation();

  const [success, setSuccess] = useState(false);

  return (
    <section className={cl.signUp}>
      <div className={cl.leftSide}>
        <Link to="/" className={cl.logoLink}>
          <Logo />
        </Link>
        <img alt="rec rout hr sign up" src={brandImg} />
      </div>
      <div className={cl.rightSide}>
        {success ? (
          <SignUpSuccess t={t} />
        ) : (
          <div>
            <h1 className={cl.title}>{t("sign_up.title")}</h1>
            {i18n.language === "en" && (
              <div className={cl.text}>
                or <Link to="/login">sign in</Link> to your account
              </div>
            )}
            {i18n.language === "sk" && (
              <div className={cl.text}>
                alebo sa <Link to="/login">prihláste</Link> do svojho konta
              </div>
            )}
            <SignUpForm setSuccess={setSuccess} />
          </div>
        )}
      </div>
    </section>
  );
};

export default SignUpBlock;
