import React from 'react';
import cl from './Plan.module.scss';
import LogoStripe from '../../../../images/logo-stripe.svg';
import CircleCheck from '../../../../images/circle-check.svg';

const Plan = ({type, text, buttonName, callback, buttonType}) => {
    return (
        <div className={cl.wrapper}>
            <div className={cl.title}>
                <img src={CircleCheck} alt="" style={{marginRight: '13px'}} />
                <p>{type}</p>
            </div>
            <p className={cl.subtitle}>{text}</p>
            {buttonName && (
                <button className={buttonType === 'red' ? `${cl.button} ${cl.button_red}` : cl.button} onClick={callback}>
                    {buttonName}
                </button>
            )}
            <img className={cl.logo} src={LogoStripe} alt="" />
        </div>
    );
};

export default Plan;
