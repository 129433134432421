import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as DescrIcon } from '../../../../images/icons-description.svg';
// eslint-disable-next-line
import api from '../../../../services/api';
import useAuth from '../../../../hooks/use-auth';

import cl from './InputsGroup.module.scss';

const InputsGroup = ({ t, presentationData, isPublic }) => {
    // eslint-disable-next-line
    const { presentation, logOut } = useAuth();

    const presentationComponentData = isPublic
        ? presentationData
        : presentation;

    const formatData = (d) => {
        if (isPublic) {
            return {
                title: d?.label || '',
                descr: d?.description || '',
            };
        } else {
            return {
                title: d?.presentation?.label || '',
                descr: d?.presentation?.description || '',
            };
        }
    };

    const [values, setValues] = useState(formatData(presentationComponentData));

    useEffect(() => {
        if (presentationComponentData && isPublic) {
            setValues(formatData(presentationComponentData));
        }
    }, [presentationComponentData, isPublic]);

    const updateText = async () => {
        if (isPublic) return;
        await api.auth
            .updateUsersCompanyPresentation({
                label: values.title,
                description: values.descr,
                id: presentationComponentData?.presentation?.id,
                is_deleted: presentationComponentData?.presentation?.is_deleted,
                company_id: presentationComponentData?.presentation?.company_id,
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response);
                    if (error.response?.data?.message === 'Token invalid') {
                        logOut();
                    }
                }
            });
    };

    return (
        <div className={cl.inputsGroup}>
            <DescrIcon
                className={values.title || values.descr ? cl.iconMod : cl.icon}
            />
            <form
                className={cl.inputsGroup}
                id="presentation-form"
                onSubmit={(e) => e.preventDefault()}
            >
                <TextareaAutosize
                    name="title"
                    className={cl.titleFild}
                    value={values.title}
                    placeholder={t('placeholders.prsentation_title')}
                    onChange={(e) =>
                        setValues((prev) => ({
                            ...prev,
                            title: e.target.value,
                        }))
                    }
                    onBlur={updateText}
                    disabled={isPublic}
                />
                <TextareaAutosize
                    className={cl.descrFild}
                    value={values.descr}
                    placeholder={t('placeholders.presentation_descr')}
                    onChange={(e) =>
                        setValues((prev) => ({
                            ...prev,
                            descr: e.target.value,
                        }))
                    }
                    onBlur={updateText}
                    disabled={isPublic}
                />
            </form>
        </div>
    );
};

export default InputsGroup;
