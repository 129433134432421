import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import "../../../../utils/i18next";

import cl from "./SetBlockHead.module.scss";

const SetBlockHead = ({ title, editMod, onConfirm, onDiscard, editModHandler, hideBtns = false }) => {
  const { t } = useTranslation();

  if (hideBtns) {
    return (
      <div className={cl.head}>
        <span>{title}</span>
      </div>
    );
  } else {
    return (
      <div className={cl.head}>
        <span>{title}</span>
        {editMod != null && (
          <>
            {!editMod ? (
              <button className={cl.editBtn} onClick={() => editModHandler(true)}>
                {t("buttons.edit")}
              </button>
            ) : (
              <div>
                <button className={cl.discardBtn} onClick={onDiscard}>
                  {t("buttons.discard")}
                </button>
                <button className={cl.confirmBtn} onClick={onConfirm}>
                  {t("buttons.confirm")}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
};

SetBlockHead.propTypes = {
  title: PropTypes.string,
  editMod: PropTypes.bool,
  editModHandler: PropTypes.func
};

export default SetBlockHead;
