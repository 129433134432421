import React from 'react';
import {ReactComponent as WhatsappIcon} from '../../../images/logo-whatsapp.svg';
import {ReactComponent as TwitterIcon} from '../../../images/logo-twitter.svg';
import {ReactComponent as MessengerIcon} from '../../../images/logo-messenger.svg';
import {ReactComponent as FacebookIcon} from '../../../images/logo-message.svg';
import {ReactComponent as LinkedinIcon} from '../../../images/socialsIcons/linkedin.svg';
import {ReactComponent as MailIcon} from '../../../images/e-mail.svg';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
} from 'react-share';
import {useTranslation} from 'react-i18next';
import cl from './SocialButtons.module.scss';

const SocialButtons = ({urlToShare}) => {
    const {t} = useTranslation();

    return (
        <div className={cl.wrapper}>
            <p className={cl.text}>{t('labels.or')}</p>
            <p className={cl.text}>{t('labels.choose_more')}</p>
            {urlToShare && (
                <div className={cl.logoWrap}>
                    <FacebookShareButton
                        url={urlToShare}
                        quote={''}
                        hashtag={'#capahr'}
                        description={'Company Presentation'}
                        className={cl.logo}>
                        <FacebookIcon />
                    </FacebookShareButton>
                    <TwitterShareButton
                        title={'Company Presentation'}
                        url={urlToShare}
                        quote={'Company Presentation'}
                        hashtag={'#capahr'}
                        description={'Company Presentation'}
                        className={cl.logo}>
                        <TwitterIcon />
                    </TwitterShareButton>
                    <WhatsappShareButton url={urlToShare} title={'Company Presentation'} className={cl.logo}>
                        <WhatsappIcon />
                    </WhatsappShareButton>
                    <FacebookMessengerShareButton url={urlToShare} title={'Company Presentation'} className={cl.logo}>
                        <MessengerIcon />
                    </FacebookMessengerShareButton>
                    <LinkedinShareButton url={urlToShare} description={''} title={'Company Presentation'} className={cl.logo}>
                        <LinkedinIcon />
                    </LinkedinShareButton>
                    <a
                        href={`mailto:?subject=Check out this presentation&body=Hello, Im sending you a link to a company video presentation, check it out! ${urlToShare}`}
                        className={cl.email}>
                        <MailIcon />
                    </a>
                </div>
            )}
        </div>
    );
};

export default SocialButtons;
