import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cl from './LogoSetBlock.module.scss';
import { LOGO_SET_BLOCK_TYPE } from '../../../../helpers/constants';

const LogoImage = ({ isProfileType, children }) => {
    if (!isProfileType) return children;
    return <div className={cl.imgWrapper}>{children}</div>;
};

const LogoSetBlock = ({ title, editMod, data, setData, auth, t, type }) => {
    const [logoUrl, setLogoUrl] = useState(null);

    const inputLogo = useRef(null);

    const isProfileType = type === LOGO_SET_BLOCK_TYPE.PROFILE;

    useEffect(() => {
        if (!data.logo) {
            setLogoUrl(null);
            return;
        }
        if (data.logo && typeof data.logo !== 'string') {
            const reader = new FileReader();
            reader.onload = function (e) {
                setLogoUrl(reader.result);
            };
            reader.readAsDataURL(data.logo);
            return;
        }

        if (typeof data.logo === 'string' || data.logo === null) {
            setLogoUrl(data.logo);
        }
    }, [data.logo]);

    const handleFiles = (e) => {
        setData({ ...data, logo: e.target.files[0] });
    };

    const handleRemoveLogo = async () => {
        const newData = { ...data, removeAvatar: '1' };
        delete newData.logo;

        setData({ ...newData });
    };

    return (
        <div className={cl.logo}>
            <div className={cl.imgCont}>
                <span>{title}</span>
                {logoUrl ? (
                    <LogoImage isProfileType={isProfileType}>
                        <img src={logoUrl} alt="company-logo" />
                    </LogoImage>
                ) : null}
                {/* <div className={cl.imgWrapper}>
                    {logoUrl && <img src={logoUrl} alt="company-logo" />}
                </div> */}
            </div>
            {editMod && (
                <div className={cl.logoManipulation}>
                    <input
                        type="file"
                        id="inputLogo"
                        ref={inputLogo}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFiles(e)}
                    />
                    <label htmlFor="inputLogo">
                        {t('buttons.upload_pictures')}
                    </label>
                    <button className={cl.dellLogo} onClick={handleRemoveLogo}>
                        {t('buttons.delete_picture')}
                    </button>
                </div>
            )}
        </div>
    );
};

LogoSetBlock.propTypes = {
    editMod: PropTypes.bool,
};

export default LogoSetBlock;
