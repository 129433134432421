import { Route, Redirect } from "react-router-dom";
import useAuth from "../../hooks/use-auth";

function PrivateRoute({ component: Component, needsPermission, ...rest }) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        const url = new URLSearchParams();
        url.set("redirect", props.location.pathname + props.location.search);

        if (needsPermission) {
          return auth.userCompany && auth.token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/profile/settings", search: url.toString() }}
            />
          );
        } else {
          return auth.userCompany && auth.token ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", search: url.toString() }} />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
