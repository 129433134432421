import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './InputCheckbox.module.scss';

const InputCheckbox = ({label, checked, error, callback}) => {
    return (
        <div className={`${styles['input-wr']} ${!checked && error ? styles['invalid'] : ''}`}>
            <label className={checked ? styles['active'] : ''}>
                <input type="checkbox" checked={checked} onChange={callback} className={styles['input']} />
                {label}
            </label>
        </div>
    );
};
InputCheckbox.propTypes = {
    label: PropTypes.string | PropTypes.object,
    checked: PropTypes.bool.isRequired,
    error: PropTypes.string,
    callback: PropTypes.func.isRequired,
};

export default memo(InputCheckbox);
