import React from 'react';
import PropTypes from 'prop-types';

import SlideInputLabel from './SlideInputLabel';
import PlayIcon from '../../../../images/atoms-icons-play.svg';
import SlideControlBox from './SlideControlBox';

import cl from './styles/ManageVideosSlider.module.scss';

const ManageVideosSlide = ({slide, handleClick}) => {
    const {id, video, thumbnail, label, duration, presentation_id, is_deleted} = slide;

    return (
        <div className={cl.manageVideosSlide}>
            <SlideInputLabel label={label} id={id} presentation_id={presentation_id} is_deleted={is_deleted} />
            <div
                className={cl.inner}
                onClick={() => handleClick(video, label, thumbnail)}
                style={{backgroundImage: thumbnail ? `url(${thumbnail})` : 'none'}}>
                <div className={cl.playBar}>
                    <img src={PlayIcon} alt="icon video play" />
                    <div className={cl.seconds}>
                        <span>{duration}</span>
                        <span>s</span>
                    </div>
                </div>
                <SlideControlBox id={id} />
            </div>
        </div>
    );
};

ManageVideosSlide.propTypes = {
    slide: PropTypes.object,
    handleClick: PropTypes.func,
};

export default ManageVideosSlide;
