import React from "react";
import { Link } from "react-router-dom";

import LoginForm from "./LoginForm";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/Login.module.scss";

const LoginContainer = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className={cl.login}>
      <div>
        <h2 className={cl.title}>{t("login.title")}</h2>
        {i18n.language === "en" && (
          <p className={cl.subtitle}>
            or{" "}
            <Link to="/sign-up" className={cl.link}>
              sign up
            </Link>{" "}
            to new account
          </p>
        )}
        {i18n.language === "sk" && (
          <p className={cl.subtitle}>
            alebo sa{" "}
            <Link to="/sign-up" className={cl.link}>
              prihláste
            </Link>{" "}
            na nové konto
          </p>
        )}
      </div>
      <LoginForm />
    </section>
  );
};

export default LoginContainer;
