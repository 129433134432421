import React from "react";

import CompanySetForm from "../settings-forms/company-set-form/CompanySetForm";

const CompanySetContainer = () => {
  return (
    <div>
      <CompanySetForm />
    </div>
  );
};

export default CompanySetContainer;
