import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as LogoPresentation } from "../../../images/profile-icons/icons-presentation.svg";
import { ReactComponent as LogoManage } from "../../../images/profile-icons/icons-manage-videos.svg";
import { ReactComponent as LogoSettings } from "../../../images/profile-icons/icons-settings.svg";
import { ReactComponent as LogoNotification } from "../../../images/profile-icons/icons-notifications.svg";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";
import ReactHtmlParser from "react-html-parser";

import Tooltip from "../../general/tooltip/Tooltip";

import useBreakpoints from "../../../hooks/use-breakpoints";

import cl from "./ProfileNavbar.module.scss";
import useAuth from "../../../hooks/use-auth";
import { NOTIFICATIONS_MOCK, UNREAD_NOTIFICATIONS } from "../../../helpers/mock";
import api from "../../../services/api";
import moment from "moment";

const formateDate = (unix_timestamp) => {
  if (unix_timestamp) {
    return moment.unix(unix_timestamp).fromNow(true);
  }
};

const NotificationItem = ({ data, t }) => {
  return (
    <div className={`${cl.notificationsPopupItem} ${Boolean(data.isUnread) ? cl.notificationsPopupItem_unread : ""}`}>
      <div className={cl.notificationsPopupItemWrapper}>
        <div>
          <p className={cl.notificationsPopupItemTitle}>{t("notifications." + data.title)}</p>
          <p className={cl.notificationsPopupItemText}>{ReactHtmlParser(data.text)}</p>
        </div>
        <p className={cl.notificationsPopupItemTimeAgo}>{formateDate(data.created_at)}</p>
      </div>
    </div>
  );
};

const ProfileNavbar = ({ img, page }) => {
  const { t } = useTranslation();

  const { profile, token } = useAuth();
  const isLoggedIn = token;
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const breakpoint = useBreakpoints();

  const handleToggleNotifications = () => {
    setShowNotifications((prev) => !prev);
  };

  useEffect(() => {
    getNotificationsData();
  }, []);

  const getNotificationsData = async () => {
    await api.auth.getNotifications().then(({ data }) => {
      setNotifications(data.data);
    });
  };

  return (
    <div className={cl.profileNav}>
      {img && <img src={img} alt="brand img" className={cl.brandImg} />}
      <nav className={cl.nav}>
        <Tooltip text={t("tooltips.unverifiedCompaniesText")} active={false}>
          <NavLink to="/profile/presentation" activeClassName={cl.active}>
            <span className={cl.icon}>
              <LogoPresentation />
            </span>
          </NavLink>
        </Tooltip>
        <Tooltip text={t("tooltips.unverifiedCompaniesText")} active={false}>
          <NavLink to="/profile/manage-videos" activeClassName={cl.active}>
            <span className={cl.icon}>
              <LogoManage />
            </span>
          </NavLink>
        </Tooltip>
        {isLoggedIn ? (
          <NavLink to="/profile/settings" activeClassName={cl.active}>
            <span className={cl.icon}>
              <LogoSettings />
            </span>
          </NavLink>
        ) : null}
      </nav>
      {isLoggedIn ? (
        <div className={cl.profile}>
          {breakpoint !== "mob" && (
            <button
              className={`${cl.notifyBtn} ${UNREAD_NOTIFICATIONS ? cl.notifyBtn_unread : ""}`}
              onClick={handleToggleNotifications}>
              <span className={cl.icon}>
                <LogoNotification />
              </span>
            </button>
          )}
          {showNotifications ? (
            <div className={cl.notificationsPopup}>
              {notifications.length ? (
                <>
                  {notifications.map((item) => {
                    return <NotificationItem t={t} data={item} />;
                  })}
                  <p className={cl.more}>{t("notifications.more")}</p>
                </>
              ) : (
                <div className={cl.emptyNotificationsMessage}>{t("notifications.empty")}</div>
              )}
            </div>
          ) : null}
          <div className={cl.avatarWr}>
            <Tooltip position="bottom-right" text={t("tooltips.unverifiedCompaniesText")} active={false} mirror>
              <NavLink to="/profile" className={cl.avatar}>
                {profile && profile.picture ? <img src={profile.picture} alt="" className={cl.avatarImg} /> : null}
              </NavLink>
            </Tooltip>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileNavbar;
