import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../UI/button/Button';
import cl from './PaymentForm.module.scss';
import TrashIcon from '../../../../images/trash.svg';
import ArrowDownIcon from '../../../../images/arrow-down.svg';
import MailIcon from '../../../../images/mail-icon.svg';
import {ORDER_HISTORY_MOCK, ORDER_HISTORY_MOCK_EMPTY} from '../../../../helpers/mock';
import useModal from '../../../../hooks/use-modal';
import Plan from './Plan';
import PlanAdv from '../../../general/planAdv/PlanAdv';
import api from '../../../../services/api';
import moment from 'moment';
import getStripe from '../../../../utils/stripejs';
import PriceTable from '../price-table/PriceTable';

const PaymentForm = () => {
    const {t} = useTranslation();
    const [subscriptionList, setSubscripionList] = useState([]);
    const [paymentsList, setPaymentsList] = useState([]);

    const [activeSubscription, setActiveSubscription] = useState(null);
    const [activePayment, setActivePayment] = useState(null);

    const [subscriptionStatus, setSubscriptionStatus] = useState('canceled');
    const [subscriptionEnding, setSubscriptionEnding] = useState('');

    useEffect(() => {
        getSubscriptionsList();
    }, []);

    const getSubscriptionsList = async () => {
        await api.auth.getCompanySubscriptionList().then(async ({data}) => {
            const subscriptions = data.data.subscriptions;
            const payments = data.data.payments;
            console.log(payments);
            if (subscriptions) {
                setSubscripionList(subscriptions);
                getActiveSubscription(subscriptions);
            }
            if (payments) {
                getActivePayment(payments);
            }
        });
    };

    const getActiveSubscription = subscriptions => {
        const activeSubscription = subscriptions.find(subscription => subscription.status === 'active');
        if (activeSubscription) {
            setActiveSubscription(activeSubscription);
            setSubscriptionStatus(activeSubscription.status);
            setSubscriptionEnding(formatUnixTimestamp(activeSubscription.current_period_end));
        }
    };

    const getActivePayment = payments => {
        const activePayment = payments.find(payment => payment.status === 'succeeded' && payment.amount === 9999);
        if (activePayment) {
            setPaymentsList([activePayment]);
            setActivePayment(activePayment);
            setSubscriptionStatus('active');
            //if payment active, disable subscription
            setActiveSubscription(null);
            setSubscriptionEnding('');
        }
    };

    const downloadSubscriptionInvoice = async subscriptionId => {
        await api.auth.getInvoiceOfSubscription({subscriptionId}).then(({data}) => {
            const url = data.data.invoice_link;
            const aTag = document.createElement('a');
            aTag.href = url;
            aTag.download = url.replace(/^.*[\\\/]/, '');
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
        });
    };

    const downloadPaymentInvoice = async receiptLink => {
        const url = receiptLink;
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.targer = '_blank';
        aTag.download = url.replace(/^.*[\\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };

    const cancelSubscription = async () => {
        await api.auth.cancelSubscription({subscriptionId: activeSubscription.id}).then(result => {
            console.log(result);
            setSubscriptionStatus(result.data.status);
        });
    };

    const createCheckoutSession = async priceId => {
        await api.auth.createCheckoutSession({priceId: priceId}).then(async ({data}) => {
            const stripe = await getStripe();
            return stripe.redirectToCheckout({sessionId: data.data.sessionId});
        });
    };

    const formatUnixTimestamp = unix_timestamp => {
        const milliseconds = Number(unix_timestamp) * 1000;
        return moment(milliseconds).format('DD.MM.YYYY');
    };

    return (
        <div>
            {activeSubscription && subscriptionStatus === 'active' && (
                <Plan
                    type={t('plan.premium')}
                    text={t('plan.nextPayment') + String(' ' + subscriptionEnding)}
                    buttonName={t('plan.cancel')}
                    buttonType={'red'}
                    callback={cancelSubscription}
                />
            )}
            {activePayment && subscriptionStatus === 'active' && (
                <Plan type={t('plan.lifetime')} text={t('plan.activeLifetime')} />
            )}
            {subscriptionStatus != 'active' && (
                <Plan type={t('plan.free')} text={t('plan.freeAccount')} buttonName={null} callback={createCheckoutSession} />
            )}
            <div className={cl.block}>
                <div className={cl.head}>
                    <span>{t('payments.orderHistory')}</span>
                </div>
                <div className={cl.rolesGridWrapper}>
                    <div className={cl.rolesGrid}>
                        <p className={cl.rolesItem}>{t('payments.table.period')}</p>
                        <p className={cl.rolesItem}>{'Status'}</p>
                        <p className={cl.rolesItem}>{t('payments.table.date')}</p>
                        <p className={cl.rolesItem}>{t('payments.table.price')}</p>
                        <p className={cl.rolesItem}>{t('payments.table.payment')}</p>
                        <p className={cl.rolesItem}></p>
                    </div>
                    {subscriptionList.map(unit => {
                        return (
                            <div className={cl.rolesGrid}>
                                <p className={cl.rolesItem}>
                                    <span>{t('payments.period')}</span>
                                </p>
                                <p className={cl.rolesItem}>{unit.status}</p>
                                <p className={cl.rolesItem}>{formatUnixTimestamp(unit.created)}</p>
                                <p className={cl.rolesItem}>
                                    <span>
                                        {Number(unit.plan.amount) / 100} {unit.plan.currency}
                                    </span>
                                </p>
                                <p className={cl.rolesItem}>
                                    <span>{unit.id}</span>
                                </p>
                                <div className={cl.rolesItem}>
                                    <button className={cl.text_button} onClick={() => downloadSubscriptionInvoice(unit.id)}>
                                        {t('payments.invoice')}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    {paymentsList.map(unit => {
                        return (
                            <div className={cl.rolesGrid}>
                                <p className={cl.rolesItem}>
                                    <span>{t('payments.lifetime')}</span>
                                </p>
                                <p className={cl.rolesItem}>{'Active'}</p>
                                <p className={cl.rolesItem}>{formatUnixTimestamp(unit.created)}</p>
                                <p className={cl.rolesItem}>
                                    <span>
                                        {Number(unit.amount) / 100} {unit.currency}
                                    </span>
                                </p>
                                <p className={cl.rolesItem}>
                                    <span>{unit.id}</span>
                                </p>
                                <div className={cl.rolesItem}>
                                    <button
                                        className={cl.text_button}
                                        onClick={() => downloadPaymentInvoice(unit.charges?.data[0].receipt_url)}>
                                        {t('payments.invoice')}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    {!subscriptionList.length && !paymentsList.length && (
                        <div className={cl.rolesGrid}>
                            <p className={cl.rolesItem}>
                                <span className={cl.empty_text}>{t('payments.noTransaction')}</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {subscriptionStatus === 'active' && (
                <div className={cl.block}>
                    <div className={cl.head}>
                        <span>{t('payments.paymentMethod')}</span>
                    </div>
                    <div className={`${cl.cell} ${cl.card_line}`}>
                        <p>
                            {activeSubscription
                                ? `${t('payments.subscriptionEnding')} ${subscriptionEnding}`
                                : t('payments.lifetimeSubscriptionEnding')}
                        </p>
                        {activeSubscription && (
                            <div>
                                <button
                                    className={`${cl.text_button} ${cl.text_button_red}`}
                                    style={{marginRight: '32px'}}
                                    onClick={cancelSubscription}>
                                    {t('payments.remove')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/*  */}
            {subscriptionStatus != 'active' && (
                <div className={cl.priceTableCont}>
                    <PriceTable callback={priceId => createCheckoutSession(priceId)} />
                </div>
            )}
            <div className={`${cl.block} ${cl.receipt_block}`}>
                <div className={cl.head}>
                    <span>{t('payments.receiptInfo')}</span>
                </div>
                <div className={`${cl.padd}`}>
                    <p className={cl.text}>{t('payments.receiptInfoMessage')}</p>
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <img style={{margin: '8px 8px 0 0 '}} src={MailIcon} alt="" />
                        <textarea className={cl.textarea} placeholder={t('payments.yourMessage')}></textarea>
                    </div>
                    <hr className={cl.line} />
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{width: '216px'}}>
                            <Button>{t('payments.send')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentForm;
