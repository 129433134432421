import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import "../../../../utils/i18next";

import cl from "./styles/VideosControl.module.scss";

const VideosControl = () => {
  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);

  const clickHandler = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className={cl.videosControl}>
      <button className={cl.openBtn} onClick={clickHandler}>
        <span className={cl.circle}></span>
        <span className={cl.circle}></span>
        <span className={cl.circle}></span>
      </button>
      <div className={`${cl.videosMenu} ${openMenu ? cl.active : ""}`}>
        <button className={cl.btn}>
          <span className={`${cl.icon} icon-rename`}></span>
          <span className={cl.btnName}>{t("buttons.rename")}</span>
        </button>
        <button className={cl.btn}>
          <span className={`${cl.icon} icon-share`}></span>
          <span className={cl.btnName}>{t("buttons.share")}</span>
        </button>
        <button className={cl.btn}>
          <span className={`${cl.icon} icon-delete`}></span>
          <span className={cl.btnName}>{t("buttons.delete")}</span>
        </button>
      </div>
    </div>
  );
};

export default VideosControl;
