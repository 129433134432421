import React, { useState, useMemo, useCallback, useEffect } from 'react';

import SetBlockHead from '../../settings-blocks-reusable/set-block-head/SetBlockHead';
import LogoSetBlock from '../../settings-blocks/logo-set-block/LogoSetBlock';
import FormInput from '../../../UI/form-input/FormInput';
import FormInputSelect from '../../../UI/form-input-select/FormInputSelect';
import profileSetInputsList from './profileSetInputsList';
import CompanySetNotification from '../../pop-up-blocks/company-set-notification/CompanySetNotification';

import { useTranslation } from 'react-i18next';
import '../../../../utils/i18next';

import useAuth from '../../../../hooks/use-auth';
import useModal from '../../../../hooks/use-modal';

import api from '../../../../services/api';
import { LOGO_SET_BLOCK_TYPE } from '../../../../helpers/constants';

const ProfileSetForm = ({ page }) => {
    const { t } = useTranslation();

    const auth = useAuth();
    const { setModalContextData, hide } = useModal();

    const { user, profile, roles } = auth;

    // const data = useMemo(() => {
    //     return {
    //         logo: profile?.picture ? profile.picture : '',
    //         firstname: profile?.firstname ? profile.firstname : '',
    //         lastname: profile?.lastname ? profile.lastname : '',
    //         email: profile?.email ? profile.email : '',
    //         role: profile?.role_id ? profile.role_id : '',
    //     };
    // }, [profile]);

    const setData = useCallback(() => {
        return {
            logo: profile?.picture ? profile.picture : '',
            firstname: profile?.firstname ? profile.firstname : '',
            lastname: profile?.lastname ? profile.lastname : '',
            email: profile?.email ? profile.email : '',
            role: profile?.role_id ? profile.role_id : '',
        };
    }, [profile]);

    const [editMod, setEditMod] = useState(false);
    const [values, setValues] = useState({
        ...setData(),
    });

    useEffect(() => {
        setValues({
            ...setData(),
        });
    }, [profile, setData]);

    const [err, setErr] = useState('');

    const inputs = useMemo(
        () => profileSetInputsList(editMod, auth, t),
        [editMod, auth, t]
    );

    const discardHandler = () => {
        setEditMod(false);
        setErr('');
        setValues({
            ...setData(),
        });
    };

    const handleSubmit = async () => {
        auth.setIsLoaded(false);
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'logo' && typeof values.logo === 'object') {
                formData.append('file', values.logo);
            } else formData.append(key, values[key]);
        });
        let logoUrl = '';
        if (values.logo && typeof values.logo === 'object') {
            const reader = new FileReader();
            reader.onload = function (e) {
                logoUrl = reader.result;
            };
            reader.readAsDataURL(values.logo);
        }
        await api.auth
            .updateUserProfile(formData)
            .then((response) => {
                setEditMod(false);
                setErr('');
                setModalContextData({
                    isOpen: true,
                    context: <CompanySetNotification closeModal={hide} />,
                });
                auth.setUserCompany({
                    ...auth.userCompany,
                    ...values,
                    logo: logoUrl,
                });
            })
            .catch((error) => {
                if (error.response) {
                    // setErr(t("errors.required"));
                    console.error(error.response);
                    if (error.response?.data?.message === 'Token invalid') {
                        auth.logOut();
                    }
                } else if (error.request) {
                    console.error(error.request);
                    setErr(t('errors.server_error'));
                } else {
                    console.error('Error', error.message);
                }
                console.error(error.config);
            })
            .finally(auth.setIsLoaded(true));
    };

    return (
        <div className="profile-settings-form" id="profileSetForm">
            <SetBlockHead
                editMod={editMod}
                onDiscard={discardHandler}
                onConfirm={handleSubmit}
                editModHandler={setEditMod}
                title={t('profile_page.my_profile_info')}
            />
            {err && <p className="set-form-err">{err}</p>}
            <LogoSetBlock
                title={t('profile_page.picture')}
                type={LOGO_SET_BLOCK_TYPE.PROFILE}
                editMod={editMod}
                data={values}
                setData={setValues}
                auth={auth}
                t={t}
            />
            <div
                className={`inputs ${
                    page === 'profile' ? 'profile-set-inputs' : ''
                }`}
            >
                {inputs.map((input) => {
                    if (input.name === 'role') {
                        return (
                            <FormInputSelect
                                key={input.id}
                                {...input}
                                options={roles}
                                value={values[input.name]}
                                setState={setValues}
                            />
                        );
                    }
                    return (
                        <FormInput
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            validator={input.validator}
                            setState={setValues}
                        />
                    );
                })}
            </div>
            <SetBlockHead
                editMod={editMod}
                onDiscard={discardHandler}
                onConfirm={handleSubmit}
                editModHandler={setEditMod}
                title={t('profile_page.other')}
                hideBtns={true}
            />
            {err && <p className="set-form-err">{err}</p>}
        </div>
    );
};

export default ProfileSetForm;
