import React from "react";
import { Link } from "react-router-dom";

import ChangePassContainer from "../components/landing/login/ChangePassContainer";
import { ReactComponent as Logo } from "../images/capa-hr-logo.svg";

import useBreakpoints from "../hooks/use-breakpoints";

import styles from "../styles/page-styles/Login.module.scss";

const ResetPassword = () => {
  const breakpoint = useBreakpoints();

  return (
    <div className={styles["login-page"]}>
      {breakpoint !== "mob" && (
        <div className={styles["login-aside"]}>
          <Link to="/" className={styles["logo-link"]}>
            <Logo />
          </Link>
        </div>
      )}
      <div className={styles["login-form"]}>
        <ChangePassContainer />
      </div>
    </div>
  );
};

export default ResetPassword;
