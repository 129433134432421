import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import cl from "./InputText.module.scss";

const InputText = ({
  placeholder,
  id,
  icon,
  value,
  readOnly,
  error,
  disabled = false,
  callback,
  isFormValid,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <div
      className={`${cl.inputWr}  ${disabled ? cl.disabled : ""} ${
        readOnly ? cl.readOnly : ""
      } ${error && (isDirty || isFormValid === false) ? cl.invalid : ""}`}
    >
      {icon && (
        <span
          className={`${icon} ${cl.icon} ${value && !error ? cl.valid : ""}`}
        ></span>
      )}
      <input
        type="text"
        id={id}
        placeholder={placeholder}
        className={cl.input}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={callback}
        onBlur={() => setIsDirty(true)}
      />
      {error && (isDirty || isFormValid === false) && (
        <span className={cl.err}>{error}</span>
      )}
    </div>
  );
};

InputText.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

export default memo(InputText);
