import React, {useState, useMemo, useRef, useEffect} from 'react';

import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';

import VideosControl from './VideosControl';
import Slide from './MV-Slide';
import Video from '../../video/Video';

import useAuth from '../../../../hooks/use-auth';
import useModal from '../../../../hooks/use-modal';
import useBreakpoints from '../../../../hooks/use-breakpoints';

import cl from './styles/ManageVideosSlider.module.scss';

const ManageVideosSlider = ({t, slidesIndexes = []}) => {
    const {presentation} = useAuth();
    const {show} = useModal();
    const breakpoint = useBreakpoints();

    const startModNum = useMemo(() => {
        if (breakpoint === 'mob') return 1;
        if (breakpoint === 'tabl' || breakpoint === 'desk') return 5;
    }, [breakpoint]);

    const [options, setOptions] = useState({
        activeSlideIdx: 1,
        startModNum: startModNum,
    });

    const swiperRef = useRef();

    const onSlideChange = i => {
        setOptions({
            activeSlideIdx: i + 1,
            startModNum: i + startModNum,
        });
    };

    useEffect(() => {
        const slider = swiperRef.current.swiper;
        slider.on('activeIndexChange', () => {
            onSlideChange(slider.activeIndex);
        });

        return () => {
            slider.off('activeIndexChange', () => {
                onSlideChange(slider.activeIndex);
            });
        };
        // eslint-disable-next-line
    }, []);

    const slides = useMemo(() => {
        if (presentation && presentation.sections) return [...presentation.sections];

        return [];
    }, [presentation]);

    const openVideo = (video, label, thumbnail) => {
        show(true, <Video url={video} label={label} thumbnail={thumbnail} />);
    };

    const trimmedSlides = useMemo(() => {
        return slidesIndexes.length > 0 ? slides.filter((slide, index) => slidesIndexes.includes(index)) : slides;
    }, [slides, slidesIndexes]);

    return (
        <div className={`${cl.manageVideosSlider} manage-videos-slider`}>
            {breakpoint === 'mob' && <VideosControl />}
            <div className={cl.wrapper}>
                <div className={cl.progressBar}>
                    <span>{trimmedSlides && trimmedSlides.length !== 0 ? `${options.activeSlideIdx}` : '00'}</span>
                    <span>
                        {trimmedSlides && trimmedSlides.length !== 0 ? `${trimmedSlides.length}` : t('presentation.no_video')}{' '}
                    </span>
                </div>
                <div className={cl.positionBlock}>
                    <Swiper
                        ref={swiperRef}
                        // spaceBetween={15}
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        breakpoints={{
                            768: {
                                spaceBetween: 15,
                            },
                        }}>
                        {trimmedSlides &&
                            trimmedSlides.length !== 0 &&
                            trimmedSlides.map((slide, i) => {
                                return (
                                    <SwiperSlide
                                        key={`Manage-Videos-Slide-${i}`}
                                        className={`${i === options.startModNum - 1 ? 'before-mod-slide' : ''} ${
                                            i >= options.startModNum ? `mod-slide mod-slide-${i + 1 - options.startModNum}` : ''
                                        }`}>
                                        <Slide slide={slide} handleClick={openVideo} />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default ManageVideosSlider;
