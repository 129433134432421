import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/button/Button";
import cl from "./RolesSetForm.module.scss";
import TrashIcon from "../../../../images/trash.svg";
import ArrowDownIcon from "../../../../images/arrow-down.svg";
import { ROLES_MOCK } from "../../../../helpers/mock";
import InviteCoworkerModal from "./InviteCoworkerModal";
import useModal from "../../../../hooks/use-modal";
import CircleCheck from "../../../../images/circle-check.svg";
import RoleItem from "./RoleItem";
import api from "../../../../services/api";

const RolesSetForm = () => {
  const { t } = useTranslation();
  const [companyUsers, setCompanyUsers] = useState([]);

  const { setModalContextData, hide } = useModal();

  const handleInviteCoworker = async () => {
    setModalContextData({
      isOpen: true,
      context: <InviteCoworkerModal closeModal={hide} t={t} />
    });
  };

  useEffect(() => {
    getCompanyUsersData();
  }, []);

  const getCompanyUsersData = async () => {
    await api.auth.getCompanyUsers().then(({ data }) => {
      setCompanyUsers(data.data);
    });
  };

  const removeUser = async (id) => {
    await api.auth.removeCompanyUser({ id });
    const list = companyUsers.filter((user) => user.id !== id);
    setCompanyUsers(list);
  };

  return (
    <div>
      <div className={cl.head}>
        <span>{t("coworkers_roles.title")}</span>
        <button className={cl.editBtn} onClick={handleInviteCoworker}>
          {t("coworkers_roles.invite")}
        </button>
      </div>
      <div className={cl.rolesGridWrapper}>
        <div className={cl.rolesGrid}>
          <p className={cl.rolesItem}>{t("coworkers_roles.name")}</p>
          <p className={cl.rolesItem}>{t("coworkers_roles.email")}</p>
          <p className={cl.rolesItem}>{t("coworkers_roles.role")}</p>
          <p className={cl.rolesItem}></p>
        </div>
        {companyUsers.map((unit) => {
          return <RoleItem key={unit.id} unit={unit} removeUser={removeUser} />;
        })}
      </div>
    </div>
  );
};

export default RolesSetForm;
