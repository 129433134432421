import React from 'react';
import ProfileNavbar from '../../components/profile/profile-navbar/ProfileNavbar';

import brandImg from '../../images/profile-img.png';

import useBreakpoints from '../../hooks/use-breakpoints';

import cl from './ProfileLayout.module.scss';
import Chat from '../../components/general/chat/Chat';

const ProfileLayout = ({ children, title, page = '' }) => {
    const breakpoint = useBreakpoints();

    const condition = breakpoint === 'mob' && page === 'profile';

    return (
        <div className={cl.profileLayout}>
            <ProfileNavbar
                img={page === 'profile' ? brandImg : null}
                page={page}
            />

            {title ? (
                <div className={cl.container}>
                    {title && !condition && (
                        <h1 className={cl.title}>{title}</h1>
                    )}
                </div>
            ) : null}
            {children}
            <Chat />
        </div>
    );
};

export default ProfileLayout;
