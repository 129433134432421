import React from "react";
import Button from "../../../UI/button/Button";
import cl from "./CompanySetNotification.module.scss";
import { useTranslation } from "react-i18next";

const CompanySetNotification = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className={cl.companySetNotification}>
      <h2 className={cl.title}>{t("company_details_modal.title")}</h2>
      <p className={cl.text}>
        {t("company_details_modal.text")}{" "}
        <span>{t("company_details_modal.text2")}</span>
      </p>
      <Button callback={() => closeModal(false)}>
        {t("company_details_modal.btn")}
      </Button>
    </div>
  );
};

export default CompanySetNotification;
