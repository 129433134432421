import React from "react";
import { Link } from "react-router-dom";

import Footer from "../components/landing/footer/Footer";
import Navbar from "../components/landing/navbar/Navbar";

import { ReactComponent as Logo } from "../images/capa-hr-logo-dark.svg";

import { useTranslation } from "react-i18next";
import "../utils/i18next";

import useBreakpoints from "../hooks/use-breakpoints";

import cl from "../styles/page-styles/Terms.module.scss";

const Terms = () => {
  const { t } = useTranslation();

  const breakpoint = useBreakpoints();

  return (
    <div>
      <section className={cl.terms}>
        {breakpoint !== "mob" ? (
          <Link to="/" className={cl["logo-link"]}>
            <Logo />
          </Link>
        ) : (
          <Navbar opt={"blackText"} />
        )}
        <div className={cl["terms-content"]}>
          <h1 className={cl["title-1"]}>{t("terms.t")}</h1>
          <h2 className={cl["title-2"]}>{t("terms.t1")}</h2>
          <p>{t("terms.p1")}</p>
          <h3 className={cl["title-3"]}>{t("terms.t2")}</h3>
          <p>{t("terms.p2")}</p>
          <h4 className={cl["title-4"]}>{t("terms.t3")}</h4>
          <p>{t("terms.p3")}</p>
          <h4 className={cl["title-4"]}>{t("terms.t4")}</h4>
          <p>{t("terms.p4")}</p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Terms;
