import React from "react";
import cl from "./DelCompanyBlock.module.scss";

const DelCompanyBlock = ({ t }) => {
  return (
    <div className={cl.delBtnWR}>
      <button className={cl.delBtn}>{t("buttons.delete_company")}</button>
    </div>
  );
};

export default DelCompanyBlock;
