import React from "react";

import Button from "../../UI/button/Button";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./UpgradeBlock.module.scss";

const UpgradeBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={cl.upgradeBlock}>
      <Button>{t("buttons.upgrade")}</Button>
    </div>
  );
};

export default UpgradeBlock;
