import React, { useState } from 'react';
import PlanAdv from '../components/general/planAdv/PlanAdv';
import Navbar from '../components/landing/navbar/Navbar';

import cl from '../styles/page-styles/Blog.module.scss';
import QuoteIcon from '../images/quote-icon.svg';
import exampleImage from '../images/project-capa-hr.jpg';
import blogHero from '../images/blogHero.jpg';
import blogInnerPage from '../images/blogInnerPage.jpg';
import IconShare from '../images/icon-share-blue.svg';
import TwitterLogo from '../images/logo-twitter.svg';
import BlogFooter from '../components/general/blogFooter/BlogFooter';
import { useTranslation, Trans } from 'react-i18next';

const BlogInner = () => {
    const [searchText, setSearchText] = useState('');
    const search = (e) => setSearchText(e.target.value);
    const { t } = useTranslation();

    return (
        <div className={cl.page}>
            <Navbar opt={'blackText'} searchText={searchText} search={search} />
            <div className={cl.top}></div>
            <div className={cl.container}>
                <div className={cl.fullWidthImgContainer}>
                    <img className={cl.fullWidthImg} src={blogHero} alt="" />
                    <div className={cl.fullWidthImgContent}>
                        <div>
                            <p>26.04.22 by Thomas Thompson</p>
                        </div>
                        <div className={cl.circle}>
                            <img width="14px" src={IconShare} alt="" />
                        </div>
                    </div>
                </div>
                <p className={cl.h1title}>{t('blogInner.heroTitle')}</p>
                <p className={cl.text}>
                    <Trans i18nKey="blogInner.text1" />
                </p>
            </div>
            <div className={cl.quoteBlock}>
                <div className={cl.container}>
                    <img src={QuoteIcon} alt="" className={cl.quoteImg} />
                    <p className={cl.quoteText}>{t('blogInner.quote')}</p>
                </div>
            </div>
            <div className={cl.container}>
                <p className={cl.text}>
                    <Trans i18nKey="blogInner.text2" />
                </p>
                <p className={cl.text}>
                    <Trans i18nKey="blogInner.text3" />
                </p>
                <p className={cl.text}>
                    <Trans></Trans>
                </p>
                <div
                    className={`${cl.fullWidthImgContainer} ${cl.fullWidthImgContainerInner}`}
                >
                    <img
                        className={cl.fullWidthImg}
                        src={blogInnerPage}
                        alt=""
                    />
                </div>
                <p className={cl.smallTitle}>{t('blogInner.bigTitle')}</p>
                <p className={cl.text}>{t('blogInner.text4')}</p>
                <p className={cl.text}>{t('blogInner.text5')}</p>
                <p className={cl.text}>{t('blogInner.text6')}</p>
                <p className={cl.text}>
                    <b>{t('blogInner.smallTitle1')}</b>
                </p>
                <p className={cl.text}>{t('blogInner.text7')}</p>
                <p className={cl.text}>{t('blogInner.text8')}</p>
                <p className={cl.text}>
                    <b>{t('blogInner.smallTitle2')}</b>
                </p>
                <p className={cl.text}>{t('blogInner.text9')}</p>
                <p className={cl.text}>{t('blogInner.text10')}</p>
                <p className={cl.text}>{t('blogInner.text11')}</p>
                <p className={cl.text}>{t('blogInner.text12')}</p>
                <p className={cl.text}>{t('blogInner.text13')}</p>
                <div className={cl.blogInnerBottomMargin}></div>
                <PlanAdv />
                <div className={cl.blogInnerBottomMargin2}></div>
            </div>
            <BlogFooter />
        </div>
    );
};

export default BlogInner;
