import React from 'react';
import useAuth from '../../../../hooks/use-auth';

import cl from './PresentationFooter.module.scss';
import api from '../../../../services/api';

const PresentationFooter = ({ t, id, isPublic }) => {
    const { presentation } = useAuth();
    const STARS_QUANTITY = 5;
    const averageRating = presentation?.statistics.avg_rating;

    const ratePresentation = (rating) => {
        if (!isPublic) return;
        try {
            api.auth.addPresentationRating({ id, rating });
        } catch {
            console.error('error');
        }
    };

    return (
        <footer className={cl.presentationFooter}>
            <p className={cl.statistic}>{t('presentation.footer_statistic')}</p>
            <div className={cl.info}>
                <div className={cl.infoItem}>
                    <span className={cl.descr}>{t('presentation.visits')}</span>
                    <span className={cl.value}>
                        {presentation?.statistics.visits_in_last_14_days}
                    </span>
                </div>
                <div className={cl.infoItem}>
                    <span className={cl.descr}>{t('presentation.views')}</span>
                    <span className={cl.value}>
                        {presentation?.statistics.total_visits}
                    </span>
                </div>
                <div className={cl.infoItem}>
                    <span className={cl.descr}>{t('presentation.shared')}</span>
                    <span className={cl.value}>
                        {presentation?.statistics.shares}
                    </span>
                </div>
                <div className={cl.infoItem}>
                    <span className={cl.descr}>{t('presentation.rating')}</span>
                    <div className={cl.valueGroup}>
                        <span className={cl.value}>{averageRating}</span>
                        <div className={cl.rating}>
                            {Array.apply(0, Array(STARS_QUANTITY)).map(
                                (_, i) => {
                                    return (
                                        <span
                                            className="icon-star pointer"
                                            key={i}
                                            onClick={() =>
                                                ratePresentation(i + 1)
                                            }
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default PresentationFooter;
