import React, {useEffect, useState} from 'react';
import cl from './PriceTable.module.scss';
import api from '../../../../services/api';
import {useTranslation} from 'react-i18next';
import Button from '../../../UI/button/Button';

const PriceTable = ({callback}) => {
    const {t} = useTranslation();
    const [prices, setPrices] = useState([]);

    useEffect(() => {
        getPrices();
    }, []);

    const sortPrices = (a, b) => {
        if (a.type === 'one_time' && b.type === 'recurring') {
            return 1;
        }
        if (a.type === 'recurring' && b.type === 'one_time') {
            return -1;
        }
        if (a.type === 'recurring' && b.type === 'recurring') {
            if (a.unit_amount < b.unit_amount) {
                return -1;
            } else {
                return 1;
            }
        }
    };

    const getPrices = async () => {
        await api.auth.getPrices().then(async ({data}) => {
            const prices = data.data.prices;
            if (prices) {
                const sortedPrices = prices.sort(sortPrices);
                console.log(prices);
                console.log(sortedPrices);
                setPrices(sortedPrices);
            }
        });
    };

    const getTitle = price => {
        if (price.type === 'one_time') {
            return t('plan.oneTime');
        } else if (price.type === 'recurring') {
            if (price.recurring.interval_count === 1) {
                return t('plan.monthly');
            } else if (price.recurring.interval_count === 3) {
                return t('plan.three_month');
            }
        }
    };

    const getDescription = price => {
        if (price.type === 'one_time') {
            return t('plan.oneTimeTitle');
        } else if (price.type === 'recurring') {
            if (price.recurring.interval_count === 1) {
                return t('plan.monthlyTitle');
            } else if (price.recurring.interval_count === 3) {
                return t('plan.threeMonthTitle');
            }
        }
    };

    const getPeriodPaymentText = price => {
        if (price.type === 'one_time') {
            return '';
        } else if (price.type === 'recurring') {
            if (price.recurring.interval_count === 1) {
                return t('plan.perMonth');
            } else if (price.recurring.interval_count === 3) {
                return t('plan.everyThreeMonth');
            }
        }
    };

    return (
        <div className={cl.table}>
            {prices.map((price, index) => {
                return (
                    <div className={cl.priceItem}>
                        <div className={cl.priceItem_cont}>
                            <div className={cl.priceItem_cont_info}>
                                <div className={cl.priceItem_cont_info_hiddenTopDiv}>
                                    {price.recurring?.interval_count === 3 && (
                                        <div className={cl.priceItem_cont_info_mostPopularCont}>
                                            <span className={cl.priceItem_cont_info_mostPopularCont_text}>
                                                {t('plan.mostPopular')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <span className={cl.priceItem_cont_info_title}>{getTitle(price)}</span>

                                <span className={cl.priceItem_cont_info_description}>{getDescription(price)}</span>
                            </div>
                            <div className={cl.priceItem_cont_priceCont}>
                                <div className={cl.priceItem_cont_priceCont_priceText}>
                                    <span className={cl.priceItem_cont_priceCont_priceText_value}>{`€${(
                                        price.unit_amount / 100
                                    ).toFixed(2)}`}</span>
                                    <span className={cl.priceItem_cont_priceCont_priceText_period}>
                                        {getPeriodPaymentText(price)}
                                    </span>
                                </div>
                                <div className={cl.buttonWrapper}>
                                    <Button callback={() => callback(price.id)}>{t('plan.subscribe')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PriceTable;
