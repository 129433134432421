import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import getUrlParams from "../../../utils/getUrlParams";

import InputPassword from "../../UI/input-password/InputPassword";
import Button from "../../UI/button/Button";

import useAuth from "../../../hooks/use-auth";
import api from "../../../services/api";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/Login.module.scss";

const ChangePassForm = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [passError, setPassError] = useState(t("errors.pass_1"));
  const [passErrorConfirmation, setPassErrorConfirmation] = useState(
    t("errors.pass_2")
  );

  const { email, token } = getUrlParams(useLocation().search);

  const [isFormValid, setIsFormValid] = useState(null);

  useEffect(() => {
    if (auth.userCompany && auth.token) {
      history.push("/profile/presentation");
    }
    // eslint-disable-next-line
  }, []);

  const passHandler = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (e.target.value.length < 4) {
        setPassError(t("errors.pass_1"));
      } else {
        setPassError("");
      }
    },
    [t]
  );

  const passHandlerConfirm = useCallback(
    (e) => {
      setPasswordConfirmation(e.target.value);
      if (e.target.value.length < 4) {
        setPassErrorConfirmation(t("errors.pass_1"));
      } else {
        setPassErrorConfirmation("");
      }
    },
    [t]
  );

  const submitHandler = () => {
    if (
      passError ||
      passErrorConfirmation ||
      passwordConfirmation !== password
    ) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

    const login = async () => {
      // auth.setIsLoaded(false);

      let loggedIn = false;

      await api.auth
        .setNewPassword({ password, email, token })
        .then(() => {
          api.auth.login({ email, password }).then(({ data }) => {
            auth.setToken(data.token);
            auth.setError(null);
            loggedIn = true;
          });
        })
        .catch((error) => {
          console.error(error);
          if (error.response?.data?.message) {
            auth.setError(error.response.data.message);
          } else {
            auth.setError(t("errors.server_error"));
          }
          auth.setIsLoaded(true);
        });

      if (loggedIn) {
        await api.auth
          .getUsersCompany()
          .then(({ data: d }) => {
            auth.setUserCompany(d.data);
            history.push("/profile");
          })
          .catch((error) => {
            if (error.response) {
              console.error(error.response);
              if (error.response?.data?.message) {
                auth.setError(error.response.data.message);
              }
              if (error.response?.data?.message === "Token invalid") {
                auth.setToken(null);
              }
            } else if (error.request) {
              auth.setError(t("errors.server_error"));
              console.error(error.request);
            } else {
              console.error("Error", error.message);
            }
            console.error(error.config);
          })
          .finally(() => {
            auth.setIsLoaded(true);
          });
      }
    };

    login();
  };

  const onKeyPressHandler = (e) => {
    if (e.charCode === 13) {
      submitHandler();
    }
  };

  const passwordsNotMatch =
    !passError &&
    !passErrorConfirmation &&
    password &&
    passwordConfirmation &&
    password !== passwordConfirmation;

  return (
    <form
      className={cl.form}
      id="login-form"
      onSubmit={(e) => e.preventDefault()}
      onKeyPress={(e) => onKeyPressHandler(e)}
    >
      <div>
        <InputPassword
          placeholder={t("change_password.new_pass")}
          id="password"
          icon="icon-password"
          value={password}
          error={passError}
          callback={passHandler}
          isFormValid={isFormValid}
        />
        <InputPassword
          placeholder={t("change_password.new_pass_confirm")}
          id="passwordConfirmation"
          icon="icon-password"
          value={passwordConfirmation}
          error={passErrorConfirmation}
          callback={passHandlerConfirm}
          isFormValid={isFormValid}
        />
        {passwordsNotMatch ? (
          <p className={cl["error-message"]}>
            {t("change_password.passwords_not_match")}
          </p>
        ) : null}
      </div>

      <div className={cl["btns-group"]}>
        <div>
          <Button type="submit" callback={submitHandler}>
            {t("change_password.btn")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ChangePassForm;
