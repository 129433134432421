import React, { useState, useRef } from "react";
import PropTypes, { bool } from "prop-types";

import cl from "./Tooltip.module.scss";

const Tooltip = ({
  children,
  title,
  text,
  mirror,
  XIndentsOff,
  active = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const targetRef = useRef(null);
  const showTooltip = isHovered || isFocused;

  const onMousHandler = (e) => {
    e.stopPropagation();
    setIsHovered(true);
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (targetRef.current) {
      targetRef.current.blur();
    }
  };

  if (active) {
    return (
      <div className={cl.tooltipWr} tabIndex="0">
        <div
          className={cl.tooltipTarget}
          onMouseEnter={onMousHandler}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onClick={handleClick}
          ref={targetRef}
        >
          {children}
        </div>
        <div
          className={`${cl.animationBlock} ${showTooltip ? cl.animated : ""} ${
            mirror ? cl.mirror : ""
          } ${XIndentsOff ? cl.XIndentsOff : ""}`}
        >
          {showTooltip && (
            <div className={cl.tooltipContent}>
              {title && <span className={cl.title}>{title}</span>}
              {text && <span className={cl.text}>{text}</span>}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return children;
  }
};

Tooltip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  active: bool,
};

export default Tooltip;
