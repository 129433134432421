import React from 'react';
import cl from './CheckboxSwitch.module.scss';

const CheckboxSwitch = ({ checked, toggler }) => {
    return (
        <div
            className={`${cl.wrap} ${checked ? cl.switcherChecked : ''}`}
            onClick={toggler}
        >
            <div
                className={`${cl.switcher} ${
                    checked ? cl.switcherChecked : ''
                }`}
            ></div>
        </div>
    );
};

export default CheckboxSwitch;
