import React from "react";
import PlayIcon from "../../../../images/atoms-icons-play.svg";
import PropTypes from "prop-types";

import cl from "./styles/PresentationSlider.module.scss";

const PresentationSlide = ({ slide, handleClick }) => {
  const { video, thumbnail, label, duration } = slide;

  return (
    <div
      className={cl.presentationSlide}
      onClick={() => handleClick(video, label, thumbnail)}
      style={{ backgroundImage: thumbnail ? `url(${thumbnail})` : "none" }}
    >
      <div className={cl.playBar}>
        <img src={PlayIcon} alt="icon video play" />
        <div className={cl.seconds}>
          <span>{duration}</span>
          <span>s</span>
        </div>
      </div>
    </div>
  );
};

PresentationSlide.propTypes = {
  slide: PropTypes.object,
  handleClick: PropTypes.func,
};

export default PresentationSlide;
