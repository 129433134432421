import React, { useState } from "react";
import Button from "../../../UI/button/Button";
import cl from "./SharePresentation.module.scss";
import SocialButtons from "../../../UI/social-buttons/SocialButtons";
import InputCheckbox from "../../../UI/input-checkbox/inputCheckbox";
import CheckboxSwitch from "../../../UI/checkbox-switch/CheckboxSwitch";

const SharePresentationModal = ({ closeModal, t, presentationData }) => {
  const BASE_URL = `${window.location.origin}/presentation/`;
  const copyURL = `${BASE_URL}${presentationData.link_tag ? presentationData.link_tag : ""}`;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyURL);
    closeModal(false);
  };
  const [checked, setChecked] = useState(false);
  const toggleCheck = () => setChecked((prev) => !prev);

  return (
    <div className={cl.deleteVideo}>
      <h2 className={cl.title}>{t("buttons.share_presentation")}</h2>
      <div className={cl.publish_cont}>
        <p>Publish presentation</p>
        <CheckboxSwitch checked={checked} toggler={toggleCheck} />
      </div>
      <input type="text" value={copyURL} disabled={true} className={cl.input} />
      <div className={cl.line}></div>
      <Button callback={copyToClipboard}>{t("popups.share_presentation_btn")}</Button>
      <SocialButtons urlToShare={copyURL} />
    </div>
  );
};

export default SharePresentationModal;
