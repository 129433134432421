import React, {useState, useEffect, useCallback} from 'react';

import CompanyLogo from '../presentation-blocks/company-logo/CompanyLogo';
import InputsGroup from '../presentation-blocks/inputs-group/InputsGroup';
import SharePresentation from '../presentation-blocks/share-presentation/SharePresentation';
import PresentationSlider from '../presentation-blocks/presentation-slider/PresentationSlider';
import PresentationFooter from '../presentation-blocks/presentation-footer/PresentationFooter';
import Loader from '../../general/loader/Loader';

import useAuth from '../../../hooks/use-auth';
import api from '../../../services/api';

import {useTranslation} from 'react-i18next';
import '../../../utils/i18next';

import useBreakpoints from '../../../hooks/use-breakpoints';

import cl from './PresentationContainer.module.scss';
import {PRESENTATION_TYPE} from '../../../helpers/constants';
import {useParams} from 'react-router-dom';
import Button from '../../UI/button/Button';

const PresentationContainer = ({type}) => {
    const {t} = useTranslation();
    let {linkTag} = useParams();

    const isPrivate = type === PRESENTATION_TYPE.PRIVATE;
    const isPublic = type === PRESENTATION_TYPE.PUBLIC;

    const auth = useAuth();

    const breakpoint = useBreakpoints();
    const [activeTab, setActiveTab] = useState(0);
    const [isLoaded, setIsLoaded] = useState(true);
    const [presentationId, setPresentationId] = useState(0);
    const presentationData = auth?.presentation?.presentation;

    const dataReq = useCallback(async () => {
        setIsLoaded(false);

        if (isPrivate) {
            await api.auth
                .getPresentationSections()
                .then(({data: d}) => {
                    auth.setPresentation(d.data);

                    return d.data.presentation;
                })
                .then(presentation => {
                    api.auth.incrementPresentationVisits({
                        id: presentation.id,
                    });
                    setPresentationId(presentation.id);
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response);
                        if (error.response?.data?.message === 'Token invalid') {
                            auth.logOut();
                        }
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    console.error(error.config);
                })
                .finally(() => {
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
        }

        if (isPublic) {
            await api.auth
                .getPresentationByLinkTag({link_tag: linkTag})
                .then(({data: d}) => {
                    auth.setPresentation(d.data);

                    return d.data.presentation;
                })
                .then(presentation => {
                    api.auth.incrementPresentationVisits({
                        id: presentation.id,
                    });
                    setPresentationId(presentation.id);
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response);
                        if (error.response?.data?.message === 'Token invalid') {
                            auth.logOut();
                        }
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    console.error(error.config);
                })
                .finally(() => {
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
        }
    }, [auth, linkTag, isPrivate, isPublic]);

    useEffect(() => {
        if (auth.presentation) return;
        dataReq();

        // eslint-disable-next-line
    }, []);

    if (isLoaded) {
        return (
            <div className={cl.presentationContainer}>
                <div className={cl.inner}>
                    <div className={cl.leftSide}>
                        <CompanyLogo />
                        {isPublic ? <InputsGroup t={t} presentationData={presentationData} isPublic={true} /> : null}
                        {isPrivate ? <InputsGroup t={t} /> : null}
                        {breakpoint !== 'mob' && isPrivate ? (
                            <SharePresentation presentationData={presentationData} t={t} id={presentationId} />
                        ) : null}
                    </div>
                    <div className={cl.rightSide}>
                        <div className={cl.tabCont}>
                            <div className={activeTab === 0 ? cl.tabActive : cl.tabNonActive} onClick={() => setActiveTab(0)}>
                                Všetky
                            </div>
                            <div className={activeTab === 1 ? cl.tabActive : cl.tabNonActive} onClick={() => setActiveTab(1)}>
                                Priestory
                            </div>
                            <div className={activeTab === 2 ? cl.tabActive : cl.tabNonActive} onClick={() => setActiveTab(2)}>
                                Team
                            </div>
                            <div className={activeTab === 3 ? cl.tabActive : cl.tabNonActive} onClick={() => setActiveTab(3)}>
                                Teambuilding
                            </div>
                        </div>
                        {activeTab === 0 && <PresentationSlider t={t} />}
                        {activeTab === 1 && <PresentationSlider t={t} slidesIndexes={[0, 1]} />}
                        {activeTab === 2 && <PresentationSlider t={t} slidesIndexes={[2]} />}
                        {activeTab === 3 && <div style={{height: '625px'}}></div>}
                    </div>
                    {breakpoint === 'mob' && isPrivate ? (
                        <SharePresentation presentationData={presentationData} t={t} id={presentationId} />
                    ) : null}
                </div>
                <PresentationFooter t={t} id={presentationId} isPublic={isPublic} />
            </div>
        );
    } else {
        return <Loader />;
    }
};

export default PresentationContainer;
