import React from "react";

import ProfileSetForm from "../settings-forms/profile-set-forms/ProfileSetForm";
import ManageAccountBlock from "../settings-blocks/manage-account-block/ManageAccountBlock";

const ProfileSetContainer = () => {
  return (
    <div>
      <ProfileSetForm page={"profile"} />
      <ManageAccountBlock />
    </div>
  );
};

export default ProfileSetContainer;
