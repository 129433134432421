import React from "react";

// import { ReactComponent as NotificationsIcon } from "../../../images/notifications.svg";
import { ReactComponent as NotifiActiveIcon } from "../../../images/notifications-active.svg";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import useAuth from "../../../hooks/use-auth";

import cl from "./UserInfo.module.scss";

const UserInfo = () => {
  const { t } = useTranslation();

  const { userCompany: data, logOut } = useAuth();

  return (
    <div className={cl.userInfo}>
      <div className={cl.col}>
        <span className={cl.name}>
          {data ? data.contact_person : t("errors.name_profile")}
        </span>
        <span className={cl.email}>
          {data ? data.contact_email : t("errors.email_profile")}
        </span>
      </div>
      <div className={cl.col}>
        <button className={cl.notifiBtn}>
          <NotifiActiveIcon />
        </button>
        <button className={cl.logOutBtn} onClick={logOut}>
          {t("buttons.log_out")}
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
