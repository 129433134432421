export default function profileSetInputsList(editMod, auth, t) {
    return [
        {
            id: 1,
            name: 'firstname',
            type: 'text',
            label: t('labels.name'),
            readOnly: !editMod,
            required: true,
            errorMessage: t('errors.name'),
        },
        {
            id: 2,
            name: 'lastname',
            type: 'text',
            label: t('labels.surname'),
            readOnly: !editMod,
            options: auth.industries,
            required: true,
            errorMessage: t('errors.surname'),
        },
        {
            id: 3,
            name: 'email',
            type: 'email',
            label: t('labels.e-mail'),
            readOnly: !editMod,
            required: true,
            errorMessage: t('errors.contact_email'),
            validator(value, setError) {
                const re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(String(value).toLowerCase())) {
                    setError(t('errors.contact_email'));
                } else {
                    setError('');
                }
            },
        },
        {
            id: 4,
            name: 'role',
            type: 'text',
            label: t('labels.role'),
            readOnly: !editMod,
            options: auth.industries,
            required: true,
            errorMessage: t('errors.role'),
        },
    ];
}
