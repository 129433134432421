import React from "react";

import useModal from "../../../hooks/use-modal";

import cl from "./Modal.module.scss";

const Modal = () => {
  const { modalContextData, hide } = useModal();
  const { isOpen, isVideo, context } = modalContextData;

  return (
    <div
      className={`${cl.modal} ${isOpen ? cl.active : ""} ${
        isVideo ? cl.videoMod : ""
      }`}
      onClick={hide}
    >
      <div className={cl.content} onClick={(e) => e.stopPropagation()}>
        {context}
      </div>
    </div>
  );
};

export default Modal;
