import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";
import SignUp from "../pages/SignUp";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import MyProfile from "../pages/profile/MyProfile";
import Presentation from "../pages/profile/Presentation";
import ManageVideos from "../pages/profile/ManageVideos";
import SettingsPage from "../pages/profile/Settings";
import Error from "../pages/Error";
import Loader from "../components/general/loader/Loader";
import useAuth from "../hooks/use-auth";
import PublicPresentation from "../pages/PublicPresentation";
import Blog from "../pages/Blog";
import BlogInner from "../pages/BlogInner";
import SuccessPayment from "../pages/SuccessPayment";
import ErrorPayment from "../pages/ErrorPayment";

const AppRouter = () => {
  const auth = useAuth();

  if (!auth.isLoaded) {
    return <Loader />;
  } else {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/changePassword" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/blog" component={Blog} />
        <Route path="/blog-innerPage" component={BlogInner} />
        <Route path="/presentation/:linkTag" component={PublicPresentation} />
        <PrivateRoute exact path="/profile" component={MyProfile} needsPermission />
        <PrivateRoute exact path="/profile/presentation" component={Presentation} needsPermission />
        <PrivateRoute exact path="/profile/manage-videos" component={ManageVideos} needsPermission />
        <PrivateRoute exact path="/profile/settings" component={SettingsPage} />

        <Route path="/terms" component={Terms} />
        <Route path="/policy" component={Policy} />
        <Route path="/error" component={Error} />
        <Route path="/success-payment" component={SuccessPayment} />
        <Route path="/cancel-payment" component={ErrorPayment} />
        <Redirect to="/error" />
      </Switch>
    );
  }
};

export default AppRouter;
