import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cl from "./Button.module.scss";

const Button = ({ children, type, href = "#", icon, modText = false, disabled = false, callback = null }) => {
  const classes = `${cl.btn} ${icon ? cl.withIcon : ""}  ${disabled ? cl["disabled"] : ""} ${modText && !icon ? cl.modText : ""}`;

  if (type === "link" && callback === null) {
    return (
      <a href={href} rel="noreferrer" target="_blank" className={classes}>
        {icon && <img src={icon} alt="icon" />}
        <span>{children}</span>
      </a>
    );
  } else if (type === "router-link" && callback === null) {
    return (
      <Link to={href} className={classes}>
        {icon && <img src={icon} alt="icon" />}
        <span>{children}</span>
      </Link>
    );
  }

  return (
    <button className={classes} disabled={disabled} onClick={callback}>
      {icon && <img src={icon} alt="icon" />}
      <span>{children}</span>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func
};

export default Button;
