import React from "react";

import NotificationsSetForm from "../settings-forms/profile-set-forms/NotificationsSetForm";

const NotificationsSetContainer = () => {
  return (
    <div>
      <NotificationsSetForm />
    </div>
  );
};

export default NotificationsSetContainer;
