import React from "react";
import Button from "../../UI/button/Button";
import cl from "./index.module.scss";
import AdvPic from "../../../images/adv-pic.svg";
import { useTranslation } from "react-i18next";
import api from "../../../services/api";
import getStripe from "../../../utils/stripejs";

const PlanAdv = () => {
  const { t } = useTranslation();
  const createCheckoutSession = async () => {
    await api.auth.createCheckoutSession().then(async ({ data }) => {
      const stripe = await getStripe();
      return stripe.redirectToCheckout({ sessionId: data.data.sessionId });
    });
  };
  return (
    <div className={cl.block}>
      <div className={cl.flex}>
        <img className={cl.advPic} src={AdvPic} alt="" />
        <div>
          <p className={cl.title}>{t("planAdv.title")}</p>
          <p className={cl.subtitle}>{t("planAdv.price")}</p>
        </div>
        <div className={cl.buttonWrap}>
          <Button callback={createCheckoutSession}>{t("planAdv.button")}</Button>
        </div>
      </div>
    </div>
  );
};

export default PlanAdv;
