import React, { useState, useEffect } from "react";

import SetBlockHead from "../../settings-blocks-reusable/set-block-head/SetBlockHead";

import { useTranslation } from "react-i18next";
import "../../../../utils/i18next";

import api from "../../../../services/api";
import CheckboxSwitch from "../../../UI/checkbox-switch/CheckboxSwitch";

const NotificationsSetForm = () => {
  const { t } = useTranslation();

  const [checkedLikes, setCheckedLikes] = useState();
  const [checkedShares, setCheckedShares] = useState();
  const [checkedNewSections, setCheckedNewSections] = useState();

  useEffect(() => {
    api.auth.getUserNotificationsSettings().then(({ data }) => {
      setCheckedLikes(Boolean(data.data.notif_likes));
      setCheckedShares(Boolean(data.data.notif_shares));
      setCheckedNewSections(Boolean(data.data.notif_newSections));
    });
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [checkedLikes, checkedShares, checkedNewSections]);

  const [err, setErr] = useState("");

  const toggleCheckLikes = () => {
    setCheckedLikes(!checkedLikes);
  };
  const toggleCheckShares = () => {
    setCheckedShares(!checkedShares);
  };
  const toggleCheckNewSections = () => {
    setCheckedNewSections(!checkedNewSections);
  };

  const handleSubmit = async () => {
    const data = {
      notif_likes: checkedLikes,
      notif_shares: checkedShares,
      notif_newSections: checkedNewSections
    };
    await api.auth
      .updateUserNotificationsSettings(data)
      .then((response) => {
        setErr("");
      })
      .catch((error) => {
        if (error.response) {
          // setErr(t("errors.required"));
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
          setErr(t("errors.server_error"));
        } else {
          console.error("Error", error.message);
        }
        console.error(error.config);
      });
  };

  return (
    <div className="notifications-settings-form" id="NotificationsSetForm">
      <SetBlockHead title={t("profile_page.notification_settings")} />
      <div className={`inputs notification-set-inputs`}>
        <div className={`notification-set-inputs_input`}>
          <label>{t("profile_page.notif_likes")}</label>
          <CheckboxSwitch checked={checkedLikes} toggler={toggleCheckLikes} />
        </div>
        <div className={`notification-set-inputs_input`}>
          <label>{t("profile_page.notif_shares")}</label>
          <CheckboxSwitch checked={checkedShares} toggler={toggleCheckShares} />
        </div>
        <div className={`notification-set-inputs_input`}>
          <label>{t("profile_page.notif_sections")}</label>
          <CheckboxSwitch checked={checkedNewSections} toggler={toggleCheckNewSections} />
        </div>
      </div>
      {err && <p className="set-form-err">{err}</p>}
    </div>
  );
};

export default NotificationsSetForm;
