import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import InputText from '../../UI/input-text/InputText';
import InputPassword from '../../UI/input-password/InputPassword';
import InputCheckbox from '../../UI/input-checkbox/inputCheckbox';
import Button from '../../UI/button/Button';
import LinkedInIcon from '../../../images/linkedin.svg';

import {useTranslation} from 'react-i18next';
import '../../../utils/i18next';

import useBreakpoints from '../../../hooks/use-breakpoints';

import api from '../../../services/api';

import styles from './styles/SignUpForm.module.scss';

const SignUpForm = ({setSuccess}) => {
    const {t} = useTranslation();

    const breakpoint = useBreakpoints();

    const [values, setValues] = useState({
        name: '',
        surname: '',
        company: '',
        email: '',
        pass1: '',
        pass2: '',
    });

    const [errors, setErrors] = useState({
        nameError: t('errors.name'),
        surnameError: t('errors.surname'),
        companyError: t('errors.company_name'),
        emailError: t('errors.email'),
        pass1Error: t('errors.pass_1'),
        pass2Error: t('errors.pass_2'),
    });

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [acceptedPolicy, setAcceptedPolicy] = useState(false);

    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(null);

    // nameHandler ------------------------------------------------------------
    const nameHandler = useCallback(
        e => {
            setValues(prev => ({...prev, name: e.target.value}));
            if (e.target.value.length < 2) {
                setErrors(prev => ({...prev, nameError: t('errors.name')}));
            } else {
                setErrors(prev => ({...prev, nameError: ''}));
            }
        },
        [t]
    );

    // surnameHandler ------------------------------------------------------------
    const surnameHandler = useCallback(
        e => {
            setValues(prev => ({...prev, surname: e.target.value}));
            if (e.target.value.length < 2) {
                setErrors(prev => ({...prev, surnameError: t('errors.surname')}));
            } else {
                setErrors(prev => ({...prev, surnameError: ''}));
            }
        },
        [t]
    );

    // companyHandler ------------------------------------------------------------
    const companyHandler = useCallback(
        e => {
            setValues(prev => ({...prev, company: e.target.value}));
            if (e.target.value.length < 2) {
                setErrors(prev => ({
                    ...prev,
                    companyError: t('errors.company_name'),
                }));
            } else {
                setErrors(prev => ({...prev, companyError: ''}));
            }
        },
        [t]
    );

    // emailHandler --------------------------------------------------------------
    const emailHandler = useCallback(
        e => {
            setValues(prev => ({...prev, email: e.target.value}));
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(e.target.value).toLowerCase())) {
                setErrors(prev => ({
                    ...prev,
                    emailError: t('errors.contact_email'),
                }));
            } else {
                setErrors(prev => ({...prev, emailError: ''}));
            }
        },
        [t]
    );

    // pass1Handler --------------------------------------------------------------
    const pass1Handler = useCallback(
        e => {
            setValues(prev => ({...prev, pass1: e.target.value}));
            if (e.target.value.length < 4) {
                setErrors(prev => ({...prev, pass1Error: t('errors.pass_1')}));
            } else {
                setErrors(prev => ({...prev, pass1Error: ''}));
            }
        },
        [t]
    );

    // pass2Handler --------------------------------------------------------------
    const pass2Handler = useCallback(
        e => {
            setValues(prev => ({...prev, pass2: e.target.value}));
            if (e.target.value !== values.pass1) {
                setErrors(prev => ({...prev, pass2Error: t('errors.pass_2')}));
            } else {
                setErrors(prev => ({...prev, pass2Error: ''}));
            }
        },
        [t, values.pass1]
    );

    // submitHandler --------------------------------------------------------------
    const submitHandler = () => {
        if (acceptedTerms === false || acceptedPolicy === false) {
            setError(t('errors.terms_policy'));
        } else {
            setError('');
        }
        const condition = Object.keys(errors).find(errKey => errors[errKey] !== '');
        if (acceptedTerms === false || acceptedPolicy === false || condition) {
            setIsFormValid(false);
            return;
        }
        setIsFormValid(true);

        const registrar = async () => {
            await api.auth
                .registration({
                    email: values.email,
                    password: values.pass1,
                    name: values.name,
                    lastname: values.surname,
                    company: values.company,
                })
                .then(response => {
                    setSuccess(true);
                })
                .catch(error => {
                    if (!error.isAxiosError) {
                        if (error.response.data?.message) {
                            setError(error.response.data.message);
                        } else {
                            setError(t('errors.server_error'));
                        }
                    } else {
                        setError(t('errors.server_error'));
                    }
                });
        };

        registrar();
    };
    // -----------------------------------------^---------------------------------------
    const onKeyPressHandler = e => {
        if (e.charCode === 13) {
            submitHandler();
        }
    };

    return (
        <form className={styles.form} id="sign-up-form" onSubmit={e => e.preventDefault()} onKeyPress={e => onKeyPressHandler(e)}>
            <div className={styles['input-group']}>
                <InputText
                    placeholder={t('placeholders.name')}
                    id="name"
                    icon="icon-profile"
                    value={values.name}
                    error={errors.nameError}
                    callback={nameHandler}
                    isFormValid={isFormValid}
                />
                <InputText
                    placeholder={t('placeholders.surname')}
                    icon={breakpoint === 'mob' ? 'icon-profile' : null}
                    value={values.surname}
                    error={errors.surnameError}
                    callback={surnameHandler}
                    isFormValid={isFormValid}
                />
            </div>
            <InputText
                placeholder={t('placeholders.company')}
                icon="icon-company"
                value={values.company}
                error={errors.companyError}
                callback={companyHandler}
                isFormValid={isFormValid}
            />
            <InputText
                placeholder={t('placeholders.email')}
                id="email"
                icon="icon-e-mail"
                value={values.email}
                error={errors.emailError}
                callback={emailHandler}
                isFormValid={isFormValid}
            />

            <InputPassword
                placeholder={t('placeholders.password_1')}
                id="pass1"
                icon="icon-password"
                value={values.pass1}
                error={errors.pass1Error}
                callback={pass1Handler}
                isFormValid={isFormValid}
            />
            <InputPassword
                placeholder={t('placeholders.password_2')}
                id="pass2"
                icon="icon-password"
                value={values.pass2}
                error={errors.pass2Error}
                disabled={values.pass1 && !errors.pass1Error ? false : true}
                callback={pass2Handler}
                isFormValid={isFormValid}
            />

            <div className={styles['checkbox-group']}>
                <InputCheckbox
                    label={
                        <span>
                            {t('labels.agree')}{' '}
                            <a href="/terms" target="_blank">
                                {t('labels.terms')}
                            </a>
                        </span>
                    }
                    checked={acceptedTerms}
                    error={error}
                    callback={useCallback(() => setAcceptedTerms(!acceptedTerms), [acceptedTerms])}
                />
                <InputCheckbox
                    label={
                        <span>
                            {t('labels.agree')}{' '}
                            <a href="/policy" target="_blank">
                                {t('labels.policy')}
                            </a>
                        </span>
                    }
                    checked={acceptedPolicy}
                    error={error}
                    callback={useCallback(() => setAcceptedPolicy(!acceptedPolicy), [acceptedPolicy])}
                />
            </div>

            <div className={styles['paymentInfo']}>{t('sign_up.paymentInfo')}</div>

            {error && <p className={styles['error-message']}>{error}</p>}

            <div className={styles['btns-group']}>
                <div>
                    <Button type="submit" callback={submitHandler}>
                        {t('sign_up.btn_1')}
                    </Button>
                </div>
                <div>
                    <Button icon={LinkedInIcon}>{t('sign_up.btn_2')}</Button>
                </div>
            </div>
        </form>
    );
};

SignUpForm.propTypes = {
    setSuccess: PropTypes.func.isRequired,
};

export default SignUpForm;
