import React from 'react';

import RolesSetForm from '../settings-forms/roles-set-form/RolesSetForm';

const RolesSetContainer = () => {
    return (
        <div>
            <RolesSetForm />
        </div>
    );
};

export default RolesSetContainer;
