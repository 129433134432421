import React from 'react';
import InputCode from './PassFormInput';
import styles from './PassCodeForm.module.scss';

const PassCodeForm = () => {
    return (
        <form action={styles['form']}>
            <h2 className={styles['title']}>Enter passcode</h2>
            <p className={styles['subtitle']}>
                We send you verification e-mail.
            </p>
            <InputCode
                length={4}
                onComplete={(code) => {
                    console.log('Correct');
                }}
            />
        </form>
    );
};

export default PassCodeForm;
