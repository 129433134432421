import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import { AuthContext } from '../../contexts/auth-context';
import api from '../../services/api';
import { formatRolesToSelectDataFormat } from '../../helpers/formatRolesToSelectDataFormat';

function AuthProvider(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [userCompany, setUserCompany] = useState(null);
    const [profile, setProfile] = useState(null);
    const [roles, setRoles] = useState(null);
    const [presentation, setPresentation] = useState(null);
    const [token, setTokenData] = useState(null);
    const [countries, setCountries] = useState(null);
    const [industries, setIndustries] = useState(null);
    const [error, setError] = useState(null);

    const setToken = useCallback((tokenData) => {
        setTokenData(tokenData);

        if (tokenData) {
            Cookies.set('auth-token', tokenData);
        } else {
            Cookies.remove('auth-token');
        }
    }, []);

    const logOut = useCallback(() => {
        setUserCompany(null);
        setProfile(null);
        setToken(null);
    }, [setToken]);

    const loadData = useCallback(async () => {
        const tokenData = Cookies.get('auth-token');
        setTokenData(tokenData);

        if (tokenData) {
            Promise.all([
                await api.auth
                    .getUsersCompany()
                    .then(({ data }) => {
                        setUserCompany(data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.error(error.response);
                            if (
                                error.response?.data?.message ===
                                'Token invalid'
                            ) {
                                logOut();
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        console.error(error.config);
                    }),
                await api.auth
                    .getUserProfile()
                    .then(({ data }) => {
                        setProfile(data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.error(error.response);
                            if (
                                error.response?.data?.message ===
                                'Token invalid'
                            ) {
                                logOut();
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        console.error(error.config);
                    }),
                await api.auth
                    .getRoles()
                    .then(({ data }) => {
                        setRoles(formatRolesToSelectDataFormat(data.data));
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.error(error.response);
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        console.error(error.config);
                    }),
            ]).finally(() => {
                setIsLoaded(true);
            });
        } else {
            setIsLoaded(true);
        }
    }, [logOut]);

    useEffect(() => {
        loadData();
    }, [loadData, token]);

    const contextValue = useMemo(
        () => ({
            isLoaded,
            user,
            token,
            userCompany,
            profile,
            presentation,
            countries,
            industries,
            error,
            roles,
            setIsLoaded,
            setUser,
            setToken,
            setUserCompany,
            setProfile,
            setPresentation,
            setCountries,
            setIndustries,
            setError,
            logOut,
        }),
        [
            isLoaded,
            user,
            token,
            userCompany,
            profile,
            presentation,
            countries,
            industries,
            error,
            roles,
            setIsLoaded,
            setUser,
            setToken,
            setUserCompany,
            setProfile,
            setPresentation,
            setCountries,
            setIndustries,
            setError,
            logOut,
        ]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
