import React from "react";
import { Link } from "react-router-dom";

import Footer from "../components/landing/footer/Footer";
import Navbar from "../components/landing/navbar/Navbar";

import { ReactComponent as Logo } from "../images/capa-hr-logo-dark.svg";

import { useTranslation } from "react-i18next";
import "../utils/i18next";

import useBreakpoints from "../hooks/use-breakpoints";

import cl from "../styles/page-styles/Policy.module.scss";

const Policy = () => {
  const { t } = useTranslation();

  const breakpoint = useBreakpoints();

  return (
    <div>
      <section className={cl.policy}>
        {breakpoint !== "mob" ? (
          <Link to="/" className={cl["logo-link"]}>
            <Logo />
          </Link>
        ) : (
          <Navbar opt={"blackText"} />
        )}
        <div className={cl["policy-content"]}>
          <h1 className={cl["title-1"]}>{t("policy.t")}</h1>
          <h2 className={cl["title-3"]}>{t("policy.t1")}</h2>
          <p>{t("policy.p1")}</p>
          <h2 className={cl["title-3"]}>{t("policy.t2")}</h2>
          <p>{t("policy.p2")}</p>
          <h3 className={cl["title-4"]}>{t("policy.t3")}</h3>
          <p>{t("policy.p3")}</p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Policy;
