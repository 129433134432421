import React, { useEffect } from 'react';

import ProfileLayout from '../../layouts/profile/ProfileLayout';
import TabsBlock from '../../components/profile/tabs-block/TabsBlock';
import CompanySetContainer from '../../components/profile/settings-containers/CompanySetContainer';

import useAuth from '../../hooks/use-auth';
import api from '../../services/api';

import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import RolesSetContainer from '../../components/profile/settings-containers/RolesSetContainer';
import PaymentContainer from '../../components/profile/settings-containers/PaymentContainer';

const SettingsPage = () => {
    const { t } = useTranslation();

    const auth = useAuth();

    const dataReq = async () => {
        await api.auth
            .getCountriesList()
            .then(({ data: d }) => {
                const opt = d.data.map((item) => ({
                    ...item,
                    value: item.code,
                }));
                auth.setCountries(opt);
            })
            .catch((error) => {
                console.error(error.response);
            });

        await api.auth
            .getIndustriesList()
            .then(({ data: d }) => {
                const opt = d.data.map((item) => ({ ...item, value: item.id }));
                auth.setIndustries(opt);
            })
            .catch((error) => {
                console.error(error.response);
            });
    };

    useEffect(() => {
        if (!auth.countries || !auth.industries) {
            dataReq();
        }
        // eslint-disable-next-line
    }, []);

    const tabs = [
        {
            id: 1,
            name: t('presentation_settings.presentation_details'),
            disabled: false,
            components: [CompanySetContainer],
        },
        {
            id: 2,
            name: t('presentation_settings.coworkers'),
            disabled: false,
            components: [RolesSetContainer],
        },
        {
            id: 3,
            name: t('presentation_settings.payments'),
            disabled: false,
            components: [PaymentContainer],
        },
    ];

    return (
        <ProfileLayout
            title={t('presentation_settings.presentation_settings')}
            page={'settings'}
        >
            <TabsBlock tabs={tabs} t={t} />
        </ProfileLayout>
    );
};

export default SettingsPage;
