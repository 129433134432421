import React from "react";

import AppStoreLogoSk from "../../../../images/Download_on_the_App_Store_SK.svg";
import AppStoreLogoUs from "../../../../images/Download_on_the_App_Store_US.svg";

import { useTranslation } from "react-i18next";

import cl from "./AppLink.module.scss";

const AppLink = () => {
  const { t, i18n } = useTranslation();

  return (
    <a
      href="http://capahr.sk/"
      target="_blank"
      rel="noreferrer"
      className={cl.appLink}
    >
      <img
        src={i18n.language === "en" ? AppStoreLogoUs : AppStoreLogoSk}
        alt="rec rout app logo"
      />
      <span>{t("presentation.app_store_link")}</span>
    </a>
  );
};

export default AppLink;
