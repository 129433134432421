import React from "react";

import ProfileLayout from "../../layouts/profile/ProfileLayout";
import TabsBlock from "../../components/profile/tabs-block/TabsBlock";
import ProfileSetContainer from "../../components/profile/settings-containers/ProfileSetContainer";
import UserInfo from "../../components/profile/user-info/UserInfo";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";

import useBreakpoints from "../../hooks/use-breakpoints";
import UpgradeBlock from "../../components/profile/upgrade-block/UpgradeBlock";
import NotificationsSetContainer from "../../components/profile/settings-containers/NotificationsSetContainer";

const MyProfile = () => {
  const { t } = useTranslation();

  const breakpoint = useBreakpoints();

  const tabs = [
    {
      id: 1,
      name: t("profile_page.tab_details"),
      disabled: false,
      components: [ProfileSetContainer]
    },
    {
      id: 2,
      name: t("profile_page.tab_notific"),
      disabled: false,
      components: [NotificationsSetContainer]
    }
  ];

  return (
    <ProfileLayout title={t("profile_page.my_profile_title")} page={"profile"}>
      {breakpoint === "mob" && <UserInfo />}
      <TabsBlock tabs={tabs} t={t} page={"profile"} />
      {breakpoint === "mob" && <UpgradeBlock />}
    </ProfileLayout>
  );
};

export default MyProfile;
