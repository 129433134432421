import React, {useState} from 'react';
import api from '../../../../services/api';
import Button from '../../../UI/button/Button';
import {RoleItemModal} from './RoleItem';
import ArrowDownIcon from '../../../../images/arrow-down.svg';
import cl from './RolesSetForm.module.scss';

const InviteCoworkerModal = ({closeModal, t}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [role, setRole] = useState('Editor');

    const handleTogglePopup = () => setShowPopup(prev => !prev);

    const handleInviteCoworker = () => {
        api.auth
            .insertCompanyUser({companyUser: {email, firstname, lastname, role}})
            .then(result => {
                if (result.data.success) {
                    setShowSuccessMsg(true);
                    setTimeout(() => {
                        closeModal();
                    }, 1500);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(t(`coworkers_roles.${error.response.data.message}`));
            });
    };

    const handleClosePopup = role => {
        setRole(role);
        setShowPopup(false);
    };

    return (
        <div className={cl.inviteCoworkerModal}>
            {!showSuccessMsg && (
                <React.Fragment>
                    <h2 className={cl.title}>{t('coworkers_roles.invite')}</h2>
                    <input
                        type="text"
                        placeholder={'Firstname'}
                        value={firstname}
                        onChange={e => setFirstname(e.target.value)}
                        className={cl.input}
                    />
                    <input
                        type="text"
                        placeholder={'Lastname'}
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                        className={cl.input}
                    />
                    <input
                        type="email"
                        placeholder={'Email'}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className={cl.input}
                    />
                    <div className={cl.rolesItem}>
                        <span>{role}</span>
                        <img onClick={handleTogglePopup} style={{cursor: 'pointer'}} src={ArrowDownIcon} alt="" />
                        {showPopup ? <RoleItemModal close={handleClosePopup} currentRole={role} /> : null}
                    </div>

                    <div className={cl.delimiter}></div>

                    {errorMessage && (
                        <div className={cl.errorMessage}>
                            <p>{errorMessage}</p>
                        </div>
                    )}

                    <Button callback={handleInviteCoworker}>{t('coworkers_roles.send')}</Button>
                </React.Fragment>
            )}
            {showSuccessMsg && (
                <div className={cl.inviteCoworkerModal_success}>
                    <h2>Success!</h2>
                </div>
            )}
        </div>
    );
};

export default InviteCoworkerModal;
