import React, {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Loader from './components/general/loader/Loader';
import Modal from './components/general/modal/Modal';
import CookiesModal from './components/general/cookies/CookiesModal';
import AppRouter from './router/index.jsx';
import ScrollToTop from './utils/scrollToTop';
import TagManager from 'react-gtm-module';
import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga';
import './styles/global.scss';

const tagManagerArgs = {
    gtmId: 'GTM-PWTDZVB',
};

// ReactGA.initialize('G-386DWTM1QT', {standardImplementation: true});

TagManager.initialize(tagManagerArgs);

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <div className="rec-rout-hr">
                <CookiesModal />
                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-386DWTM1QT"></script>
                    <script>
                        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-386DWTM1QT');`}
                    </script>
                    <script src="https://cdn.jsdelivr.net/npm/deepar/js/deepar.js"> </script>
                </Helmet>
                <Router basename={process.env.REACT_APP_PUBLIC_URL}>
                    <ScrollToTop />
                    <AppRouter />
                </Router>
                <Modal />
            </div>
        </Suspense>
    );
}

export default App;
