import React from 'react';

import PaymentForm from '../settings-forms/payment-form/PaymentForm';

const PaymentContainer = () => {
    return (
        <div>
            <PaymentForm />
        </div>
    );
};

export default PaymentContainer;
