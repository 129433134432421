import React from "react";
import styles from "../styles/page-styles/Error.module.scss";

const Error = () => {
  return (
    <div className={styles.error}>
      <h1 className={styles.text}>Error 404. Page not foud!</h1>
    </div>
  );
};

export default Error;
