import React, { useState } from 'react';
import PlanAdv from '../components/general/planAdv/PlanAdv';
import Navbar from '../components/landing/navbar/Navbar';

import cl from '../styles/page-styles/Blog.module.scss';
import blogHero from '../images/blogHero.jpg';
import blog1 from '../images/blog1.jpg';
import blog2 from '../images/blog2.jpg';
import blog3 from '../images/blog3.jpg';
import blog4 from '../images/blog4.jpg';
import ArrowRightBlue from '../images/icon-arrow-right-blue.svg';
import BlogFooter from '../components/general/blogFooter/BlogFooter';
import { useTranslation } from 'react-i18next';

const Blog = () => {
    const [searchText, setSearchText] = useState('');
    const search = (e) => setSearchText(e.target.value);
    const { t } = useTranslation();

    return (
        <div className={cl.page}>
            <Navbar opt={'blackText'} searchText={searchText} search={search} />
            <div className={cl.top}></div>
            <div className={cl.container}>
                <p className={cl.h1title} style={{ width: '500px' }}>
                    {t('blog.heroTitle')}
                </p>
                <div className={cl.fullWidthImgContainer}>
                    <img className={cl.fullWidthImg} src={blogHero} alt="" />
                    <div className={cl.fullWidthImgContent}>
                        <div>
                            <p className={cl.fullWidthImgTitle}>
                                {t('blog.heroImageTitle')}
                            </p>
                            <p>26.04.22</p>
                        </div>
                        <div className={cl.circle}>
                            <img width="14px" src={ArrowRightBlue} alt="" />
                        </div>
                    </div>
                </div>
                <div className={cl.halfsWrap}>
                    <a href="/blog-innerPage" className={cl.halfBlock}>
                        <div className={cl.halfBlockImg}>
                            <img src={blog1} alt="" />
                        </div>
                        <p className={cl.halfBlockTitle}>
                            {t('blog.quartTitle1')}
                        </p>
                        <p className={cl.halfBlockText}>
                            {t('blog.quartText1')}
                        </p>
                        <p className={cl.halfBlockText}>26.04.22</p>
                    </a>
                    <a href="/blog-innerPage" className={cl.halfBlock}>
                        <div className={cl.halfBlockImg}>
                            <img src={blog2} alt="" />
                        </div>
                        <p className={cl.halfBlockTitle}>
                            {t('blog.quartTitle2')}
                        </p>
                        <p className={cl.halfBlockText}>
                            {t('blog.quartText2')}
                        </p>
                        <p className={cl.halfBlockText}>26.04.22</p>
                    </a>
                </div>
                <div style={{ marginBottom: '64px' }}>
                    <PlanAdv />
                </div>
                <div className={cl.halfsWrap}>
                    <a href="/blog-innerPage" className={cl.halfBlock}>
                        <div className={cl.halfBlockImg}>
                            <img src={blog3} alt="" />
                        </div>
                        <p className={cl.halfBlockTitle}>
                            {t('blog.quartTitle3')}
                        </p>
                        <p className={cl.halfBlockText}>
                            {t('blog.quartText3')}
                        </p>
                        <p className={cl.halfBlockText}>26.04.22</p>
                    </a>
                    <a href="/blog-innerPage" className={cl.halfBlock}>
                        <div className={cl.halfBlockImg}>
                            <img src={blog4} alt="" />
                        </div>
                        <p className={cl.halfBlockTitle}>
                            {t('blog.quartTitle4')}
                        </p>
                        <p className={cl.halfBlockText}>
                            {t('blog.quartText4')}
                        </p>
                        <p className={cl.halfBlockText}>26.04.22</p>
                    </a>
                </div>
            </div>
            <BlogFooter />
        </div>
    );
};

export default Blog;
