import React from "react";

import ForgetForm from "./ForgetForm";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/Login.module.scss";

const ForgotPassContainer = () => {
  const { t } = useTranslation();

  return (
    <section className={cl.login}>
      <div>
        <h2 className={cl.title}>{t("forgot_pass.title")}</h2>
      </div>
      <ForgetForm />
    </section>
  );
};

export default ForgotPassContainer;
