import { useState } from "react";

import { ModalContext } from "../../contexts/modal-context";

function ModalProvider(props) {
  const [modalContextData, setModalContextData] = useState({
    isOpen: false,
    isVideo: false,
    context: null,
  });

  const hide = () => {
    setModalContextData({ isOpen: false, isVideo: false, context: null });
    //Note
    document.body.style.overflow = null;
  };

  const show = (isVideo, context) => {
    setModalContextData(() => ({
      isOpen: true,
      isVideo: isVideo,
      context: context,
    }));
    //Note
    document.body.style.overflow = "hidden";
  };

  return (
    <ModalContext.Provider
      value={{ modalContextData, setModalContextData, hide, show }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
