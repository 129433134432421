export default function urlSearchParams(data) {
  if (data == null) return "";
  const params = new URLSearchParams();

  Object.keys(data).forEach((key) => {
    params.append(`${key}`, `${data[key]}`);
  });
  return params;
}

// export default function urlSearchParams(data) {
//   if (data == null) return "";
//   let params = "";
//   Object.keys(data).forEach((key) => {
//     if (params === "") return (params = params + `${key}=${data[`${key}`]}`);
//     params = params + `&${key}=${data[`${key}`]}`;
//   });
//   return params;
// }
