import React from "react";
import Button from "../components/UI/button/Button";
import styles from "../styles/page-styles/ErrorPayment.module.scss";
import brandImg from "../images/brand_image.png";

const navigateToDashboard = () => {};

const ErrorPayment = () => {
  return (
    <section>
      <div className={styles.errorPayment}>
        <div className={styles.errorPayment_textCont}>
          <h3>Payment canceled!</h3>
          <p>Your payment has been canceled.</p>
          <Button type="router-link" href="/profile/settings">
            Back to dashboard
          </Button>
        </div>
      </div>
      <div className={styles.errorPayment_img}>
        <img alt="brand-img" src={brandImg} />
      </div>
    </section>
  );
};

export default ErrorPayment;
