import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

import useModal from "../../../hooks/use-modal";

import { ReactComponent as IconCross } from "../../../images/icons-cross.svg";

// import cl from "./Video.module.scss";

const Video = ({
  url,
  label,
  controls = true,
  loop = false,
  width = "100%",
  thumbnail = null,
}) => {
  const modal = useModal();

  return (
    <div className="video-container">
      <div className="video-label">
        <span>{label}</span>
        <IconCross onClick={() => modal.hide()} />
      </div>
      <div className="video-wrapper">
        <ReactPlayer
          url={url}
          controls={controls}
          loop={loop}
          playsinline
          width="100%"
          height="100%"
          light={thumbnail ? thumbnail : false}
        />
      </div>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  playsinline: PropTypes.bool,
  width: PropTypes.string,
  thumbnail: PropTypes.string,
};

export default Video;
