import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthProvider from "./providers/auth-provider";
import ModalProvider from "./providers/modal-provider";
import BreakpointsProvider from "./providers/breakpoints-provider";

ReactDOM.render(
  <React.StrictMode>
    <BreakpointsProvider>
      <AuthProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </AuthProvider>
    </BreakpointsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
