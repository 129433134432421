import React, { useState } from "react";
import { Link } from "react-router-dom";

import eu from "../../../images/eu_funded.jpg";
import infoImage from "../../../images/project-capa-hr.jpg";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import styles from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();

  const [showPopUp, setShowPopUp] = useState(false);
  return (
    <footer className={styles.footer}>
      {showPopUp && (
        <div
          className={styles["pop-up"]}
          onClick={() => setShowPopUp(!showPopUp)}
        >
          <img
            className={styles["info-image"]}
            src={infoImage}
            alt="information about the project"
          />
        </div>
      )}
      <ul>
        <li>© 2021 CapaHR s. r. o.</li>
        <li>Sliačska 1E, 831 02</li>
        <li>{t("footer.city")}</li>
        <li>
          <Link to="/terms">{t("footer.terms")}</Link>
        </li>
        <li>
          <Link to="/policy">{t("footer.policy")}</Link>
        </li>
      </ul>
      <img
        className={styles["funded-btn"]}
        src={eu}
        alt="Funded by the European Union"
        onClick={() => setShowPopUp(!showPopUp)}
      />
    </footer>
  );
};

export default Footer;
