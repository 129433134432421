import React from "react";
import SignUpContainer from "../components/landing/sign-up/SignUpContainer";

const SignUp = () => {
  return (
    <div>
      <SignUpContainer />
    </div>
  );
};

export default SignUp;
