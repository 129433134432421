import React, { useCallback, useState, useEffect } from "react";

import InputText from "../../UI/input-text/InputText";
import Button from "../../UI/button/Button";
import { useHistory } from "react-router";

import useAuth from "../../../hooks/use-auth";
import api from "../../../services/api";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/Login.module.scss";

const ForgetForm = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);

  const [emailError, setEmailError] = useState(t("errors.email"));

  const [isFormValid, setIsFormValid] = useState(null);

  useEffect(() => {
    if (auth.userCompany && auth.token) {
      history.push("/profile/presentation");
    }
    // eslint-disable-next-line
  }, []);
  const emailHandler = useCallback(
    (e) => {
      setEmail(e.target.value);
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(e.target.value).toLowerCase())) {
        setEmailError(t("errors.contact_email"));
      } else {
        setEmailError("");
      }
    },
    [t]
  );

  const submitHandler = () => {
    if (emailError) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

    const login = async () => {
      await api.auth
        .forgotPassword({ email })
        .then(({ data }) => {
          setEmailSend(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.response?.data?.message) {
            auth.setError(error.response.data.message);
          } else {
            auth.setError(t("errors.server_error"));
          }
          auth.setIsLoaded(true);
        });
    };

    login();
  };

  const onKeyPressHandler = (e) => {
    if (e.charCode === 13) {
      submitHandler();
    }
  };

  return (
    <div>
      {emailSend ? (
        <p className={cl.subtitle}>{t("forgot_pass.email_sent")}</p>
      ) : (
        <form
          className={cl.form}
          id="login-form"
          onSubmit={(e) => e.preventDefault()}
          onKeyPress={(e) => onKeyPressHandler(e)}
        >
          <div>
            <InputText
              placeholder={t("placeholders.email")}
              id="email"
              icon="icon-e-mail"
              value={email}
              error={emailError}
              callback={emailHandler}
              isFormValid={isFormValid}
            />
            {/* {auth.error && <p className={cl["error-message"]}>{auth.error}</p>} */}
          </div>

          <div className={cl["btns-group"]}>
            <div>
              <Button type="submit" callback={submitHandler}>
                {t("forgot_pass.btn")}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgetForm;
