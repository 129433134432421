import React, { useState, useCallback } from "react";
import { ReactComponent as Сross } from "../../../images/cross.svg";
import { useTranslation } from "react-i18next";
import "../../../utils/i18next";
import styles from "./CookiesModal.module.scss";

const CookiesModal = () => {
  const [cookiesIsSeen, setCookiesIsSeen] = useState(
    !!localStorage.cookiesIsSeen
  );

  const handleCookiesIsSeen = useCallback(() => {
    localStorage.setItem("cookiesIsSeen", "true");
    setCookiesIsSeen(true);
  }, [setCookiesIsSeen]);

  const { t } = useTranslation();

  return !cookiesIsSeen ? (
    <div className={styles.cookies}>
      <div className={styles["text"]}>
        <span>{t("cookie_block.text")}</span>
        <a href="https://ru.wikipedia.org/wiki/Cookie">
          {t("cookie_block.link_text")}
        </a>
      </div>
      <div className={styles["btn-close"]} onClick={handleCookiesIsSeen}>
        <Сross />
      </div>
    </div>
  ) : null;
};

export default CookiesModal;
