import React from 'react';
import PresentationContainer from '../components/profile/presentation-container/PresentationContainer';
import { PRESENTATION_TYPE } from '../helpers/constants';
import ProfileLayout from '../layouts/profile/ProfileLayout';

const PublicPresentation = () => {
    return (
        <ProfileLayout page={'presentation'}>
            <PresentationContainer type={PRESENTATION_TYPE.PUBLIC} />
        </ProfileLayout>
    );
};

export default PublicPresentation;
