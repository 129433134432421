import React from 'react';

import useAuth from '../../../../hooks/use-auth/index';

import cl from './CompanyLogo.module.scss';

const CompanyLogo = () => {
    const { userCompany, presentation } = useAuth();

    const logo = userCompany?.logo || presentation?.presentation?.logo;

    return (
        <div className={cl.imgWr}>
            {logo ? <img src={logo} alt="company logo" /> : null}
        </div>
    );
};

export default CompanyLogo;
