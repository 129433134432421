import React from 'react';

import ProfileManageVideoLayout from '../../layouts/profile/ProfileManageVideoLayout';
import ManageVideosContainer from '../../components/profile/manage-videos/manage-videos-container/ManageVideosContainer';

import { useTranslation } from 'react-i18next';
const DESCRIPTION_MOCK =
    'Our team of designers, developers, growth hackers, entrepreneurs and PhD’s is ready to grow your business. Lorem Ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.';

const ManageVideos = () => {
    const { t } = useTranslation();

    return (
        <ProfileManageVideoLayout
            title={t('manage_videos.title')}
            description={DESCRIPTION_MOCK}
            page={'manage-video'}
        >
            <ManageVideosContainer />
        </ProfileManageVideoLayout>
    );
};

export default ManageVideos;
