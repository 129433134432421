import React, { useState } from "react";
import { getInitials } from "./helpers";
import cl from "./RolesSetForm.module.scss";
import ArrowDownIcon from "../../../../images/arrow-down.svg";
import CircleCheck from "../../../../images/circle-check.svg";
import TrashIcon from "../../../../images/trash.svg";
import api from "../../../../services/api";
// import { useOutsideClick } from '../../../../hooks/use-outside-click';
// import { useRef } from 'react/cjs/react.production.min';

export const RoleItemModal = ({ close, currentRole }) => {
  // const modalRef = useRef(null);
  // useOutsideClick(modalRef, close);

  return (
    <div
      className={cl.change_role_modal}
      // ref={modalRef}
    >
      <div className={cl.change_role_modal_item} onClick={() => close("Admin")}>
        <span>Admin</span>
        {currentRole === "Admin" && <img src={CircleCheck} alt="" />}
      </div>
      <div className={cl.change_role_modal_item} onClick={() => close("Creator")}>
        <span>Creator</span>
        {currentRole === "Creator" && <img src={CircleCheck} alt="" />}
      </div>
      <div className={cl.change_role_modal_item} onClick={() => close("Editor")}>
        <span>Editor</span>
        {currentRole === "Editor" && <img src={CircleCheck} alt="" />}
      </div>
    </div>
  );
};

const RoleItem = ({ unit, removeUser }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [coworker, setCoworker] = useState(unit);
  const handleTogglePopup = () => setShowPopup((prev) => !prev);

  const handleClosePopup = (role) => {
    var updatedCoworker = { ...coworker };
    updatedCoworker.role = role;
    setCoworker(updatedCoworker);
    setShowPopup(false);
    api.auth.updateCompanyUser({ companyUser: updatedCoworker });
  };

  return (
    <div className={cl.rolesGrid}>
      <p className={cl.rolesItem}>
        <div className={cl.avatar}>{getInitials(coworker.firstname + " " + coworker.lastname)}</div>
        <span>{coworker.firstname + " " + coworker.lastname}</span>
      </p>
      <p className={cl.rolesItem}>{coworker.email}</p>
      <div className={cl.rolesItem}>
        <span>{coworker.role}</span>
        {coworker.role !== "Owner" ? (
          <img onClick={handleTogglePopup} style={{ cursor: "pointer" }} src={ArrowDownIcon} alt="" />
        ) : null}
        {coworker.role !== "Owner" && showPopup ? <RoleItemModal close={handleClosePopup} currentRole={coworker.role} /> : null}
      </div>
      <div className={cl.rolesItem}>
        {coworker.role !== "Owner" ? (
          <img style={{ cursor: "pointer" }} src={TrashIcon} alt="" onClick={() => removeUser(coworker.id)} />
        ) : null}
      </div>
    </div>
  );
};

export default RoleItem;
