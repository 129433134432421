import React from "react";
import Button from "../components/UI/button/Button";
import styles from "../styles/page-styles/SuccessPayment.module.scss";
import brandImg from "../images/brand_image.png";

const navigateToDashboard = () => {};

const SuccessPayment = () => {
  return (
    <section>
      <div className={styles.successPayment}>
        <div className={styles.successPayment_textCont}>
          <h3>Subscription successful!</h3>
          <div className={styles.successPayment_textCont_button}>
            <Button type="router-link" href="/profile/settings">
              Back to dashboard
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.successPayment_img}>
        <img alt="brand-img" src={brandImg} />
      </div>
    </section>
  );
};

export default SuccessPayment;
