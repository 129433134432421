import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../../utils/i18next";
import cl from "./InputPassword.module.scss";

const InputPassword = ({
  id,
  icon,
  value,
  error,
  placeholder,
  disabled = false,
  callback,
  isFormValid,
}) => {
  const { t } = useTranslation();

  const [isDirty, setIsDirty] = useState(false);
  const [showPass, setShowPass] = useState(false);

  return (
    <div
      className={`${cl.inputWr} ${disabled ? cl.disabled : ""} ${
        error && (isDirty || isFormValid === false) ? cl.invalid : ""
      }`}
    >
      <span
        className={`${icon} ${cl.icon} ${value && !error ? cl.valid : ""}`}
      ></span>
      <input
        type={showPass ? "text" : "password"}
        id={id}
        placeholder={placeholder}
        className={cl.input}
        disabled={disabled}
        value={value}
        onChange={callback}
        onBlur={() => {
          setIsDirty(true);
        }}
      />
      <button onClick={() => setShowPass(!showPass)} className={cl.btnShow}>
        {showPass ? t("buttons.hide") : t("buttons.show")}
      </button>
      {error && (isDirty || isFormValid === false) && (
        <span className={cl.err}>{error}</span>
      )}
    </div>
  );
};
InputPassword.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

export default memo(InputPassword);
