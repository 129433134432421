import React, { useState, useMemo, useEffect } from 'react';

import SetBlockHead from '../../settings-blocks-reusable/set-block-head/SetBlockHead';
import LogoSetBlock from '../../settings-blocks/logo-set-block/LogoSetBlock';
import FormInput from '../../../UI/form-input/FormInput';
import FormInputSelect from '../../../UI/form-input-select/FormInputSelect';
import companySetInputsList from './companySetInputsList';
import DelCompanyBlock from '../../settings-blocks/del-company-block/DelCompanyBlock';
import CompanySetNotification from '../../pop-up-blocks/company-set-notification/CompanySetNotification';

import { useTranslation } from 'react-i18next';
import '../../../../utils/i18next';

import useAuth from '../../../../hooks/use-auth';
import useModal from '../../../../hooks/use-modal';

import api from '../../../../services/api';
import { useRef } from 'react/cjs/react.development';
import { LOGO_SET_BLOCK_TYPE } from '../../../../helpers/constants';

const CompanySetForm = () => {
    const { t } = useTranslation();

    const auth = useAuth();
    const { setModalContextData, hide } = useModal();

    const { userCompany: d } = auth;

    const data = useMemo(() => {
        return {
            logo: d?.logo ? d.logo : '',
            name: d?.name ? d.name : '',
            industry_id: d?.industry_id ? d.industry_id : '',
            address: d?.address ? d.address : '',
            zip: d?.zip ? d?.zip : '',
            status: d?.status ? d.status : '',
            country: d?.country ? d.country : '',
            vat_id: d?.vat_id ? d.vat_id : '',
            tax_id: d?.tax_id ? d.tax_id : '',
            contact_person: d?.contact_person ? d.contact_person : '',
            contact_phone: d?.contact_phone ? d.contact_phone : '',
            contact_email: d?.contact_email ? d.contact_email : '',
        };
    }, [d]);

    const [editMod, setEditMod] = useState(false);
    const [values, setValues] = useState({
        ...data,
    });

    const [err, setErr] = useState('');

    const inputs = useMemo(
        () => companySetInputsList(editMod, auth, t),
        [editMod, auth, t]
    );

    const discardHandler = () => {
        setEditMod(false);
        setErr('');
        setValues({
            ...data,
        });
    };

    const handleSubmit = async () => {
        let formIsValid = true;

        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            if (key !== 'logo' && values[key] === '') {
                setErr(t('errors.required'));
                formIsValid = false;
                return;
            }
            if (key === 'logo') {
                if (typeof values.logo === 'object') {
                    formData.append('file', values.logo);
                }
            } else formData.append(key, values[key]);
        });

        let logoUrl = '';

        if (values.logo && typeof values.logo === 'object') {
            const reader = new FileReader();
            reader.onload = function (e) {
                logoUrl = reader.result;
            };
            reader.readAsDataURL(values.logo);
        }

        if (formIsValid) {
            auth.setIsLoaded(false);

            await api.auth
                .updateUsersCompany(formData)
                .then((response) => {
                    setEditMod(false);
                    setErr('');
                    setModalContextData({
                        isOpen: true,
                        context: <CompanySetNotification closeModal={hide} />,
                    });
                    auth.setUserCompany({
                        ...auth.userCompany,
                        ...values,
                        logo: logoUrl,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        console.error(error.response);
                        if (error.response?.data?.message === 'Token invalid') {
                            auth.logOut();
                            return;
                        }
                    } else if (error.request) {
                        console.error(error.request);
                        setErr(t('errors.server_error'));
                    } else {
                        console.error('Error', error.message);
                    }
                })
                .finally(auth.setIsLoaded(true));
        }
    };

    return (
        <div className="company-settings-form" id="companySetForm">
            <SetBlockHead
                editMod={editMod}
                onDiscard={discardHandler}
                onConfirm={handleSubmit}
                editModHandler={setEditMod}
                title={t('presentation_settings.company_detail')}
            />
            {err && <p className="set-form-err">{err}</p>}
            <LogoSetBlock
                title={t('presentation_settings.company_logo')}
                type={LOGO_SET_BLOCK_TYPE.COMPANY}
                editMod={editMod}
                data={values}
                setData={setValues}
                auth={auth}
                t={t}
            />
            <div className="inputs">
                {inputs.slice(0, 8).map((input) => {
                    if (input.type === 'select') {
                        return (
                            <FormInputSelect
                                key={input.id}
                                {...input}
                                value={values[input.name]}
                                setState={setValues}
                            />
                        );
                    } else {
                        return (
                            <FormInput
                                key={input.id}
                                {...input}
                                value={values[input.name]}
                                validator={input.validator}
                                setState={setValues}
                            />
                        );
                    }
                })}
            </div>
            <DelCompanyBlock t={t} />
            <SetBlockHead
                editMod={editMod}
                onDiscard={discardHandler}
                onConfirm={handleSubmit}
                editModHandler={setEditMod}
                title={t('presentation_settings.contact_info')}
            />
            {err && <p className="set-form-err">{err}</p>}
            <div className="inputs">
                {inputs.map((input, i) => {
                    if (i > 7) {
                        return (
                            <FormInput
                                key={input.id}
                                {...input}
                                value={values[input.name]}
                                validator={input.validator}
                                setState={setValues}
                            />
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default CompanySetForm;
