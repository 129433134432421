import React, { useState } from 'react';
import Button from '../../../UI/button/Button';
import SocialButtons from '../../../UI/social-buttons/SocialButtons';
import cl from './styles/ShareVideoModal.module.scss';
import ArrowDownIcon from '../../../../images/arrow-down.svg';
import AtomsIcon from '../../../../images/atoms-icon.svg';

const ShareVideoModal = ({ closeModal, t }) => {
    const [input, setInput] = useState('');
    const [showEmbed, setShowEmber] = useState(false);
    const handleInviteCoworker = () => {
        console.log('invite');
    };
    const handleToggleShowEmbed = () => {
        setShowEmber((prev) => !prev);
    };

    return (
        <div className={cl.shareVideoModal}>
            {/* <h2 className={cl.title}>{t('coworkers_roles.invite')}</h2> */}
            <h2 className={cl.title}>Share Video</h2>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={cl.input}
            />
            <div
                className={cl.flex_c}
                style={{ justifyContent: 'space-between' }}
            >
                <div className={cl.flex_c}>
                    <span>Embed video</span>
                    <img
                        onClick={handleToggleShowEmbed}
                        style={{ cursor: 'pointer' }}
                        src={ArrowDownIcon}
                        alt=""
                    />
                </div>
                <div>
                    <button className={cl.button}>
                        <img
                            onClick={handleToggleShowEmbed}
                            style={{ cursor: 'pointer' }}
                            src={AtomsIcon}
                            alt=""
                        />
                        <span>Copy code</span>
                    </button>
                </div>
            </div>
            {showEmbed ? (
                <p
                    className={cl.text_frame}
                >{`<iframe width="560" height="315" src="https://www.youtube.com/embed/BP4EvLtwwMo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}</p>
            ) : null}
            <div className={cl.line}></div>
            <div className={cl.flex_between}>
                <span>Link to video</span>
                <div style={{ width: '100px' }}>
                    <button className={cl.button}>Copy link</button>
                </div>
            </div>
            <div className={cl.line}></div>
            <SocialButtons
                whatsappUrl={'https://www.youtube.com/'}
                twitterUrl={'https://www.youtube.com/'}
                messengerUrl={'https://www.youtube.com/'}
                messageUrl={'https://www.youtube.com/'}
                instagramUrl={'https://www.youtube.com/'}
            />
        </div>
    );
};

export default ShareVideoModal;
