import React, {useCallback, useState, useEffect} from 'react';
import {useHistory} from 'react-router';

import InputText from '../../UI/input-text/InputText';
import InputPassword from '../../UI/input-password/InputPassword';
import ForgotPassBtn from './ForgotPassBtn';
import Button from '../../UI/button/Button';
import LinkedInIcon from '../../../images/linkedin.svg';

import useAuth from '../../../hooks/use-auth';
import api from '../../../services/api';

import {useTranslation} from 'react-i18next';
import '../../../utils/i18next';

import cl from './styles/Login.module.scss';

const LoginComponent = () => {
    const {t} = useTranslation();
    const auth = useAuth();
    const history = useHistory();
    const [twoFactForm, setTwoFactForm] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');

    const [emailError, setEmailError] = useState(t('errors.email'));
    const [passError, setPassError] = useState(t('errors.pass_1'));
    const [codeError, setCodeError] = useState(t('errors.code'));

    const [isFormValid, setIsFormValid] = useState(null);
    const [isCodeFormValid, setIsCodeFormValid] = useState(null);

    useEffect(() => {
        if (auth.userCompany && auth.token) {
            history.push('/profile/presentation');
        }
        // eslint-disable-next-line
    }, []);

    const emailHandler = useCallback(
        e => {
            setEmail(e.target.value);
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(e.target.value).toLowerCase())) {
                setEmailError(t('errors.contact_email'));
            } else {
                setEmailError('');
            }
        },
        [t]
    );

    const passHandler = useCallback(
        e => {
            setPassword(e.target.value);
            if (e.target.value.length < 4) {
                setPassError(t('errors.pass_1'));
            } else {
                setPassError('');
            }
        },
        [t]
    );

    const codeHandler = useCallback(
        e => {
            setCode(e.target.value);
            if (e.target.value.length != 4) {
                setCodeError(t('errors.code'));
            } else {
                setCodeError('');
            }
        },
        [t]
    );

    const show2FAForm = () => {
        setTwoFactForm(true);
    };

    const submitHandler = () => {
        if (!twoFactForm) {
            if (emailError || passError) {
                setIsFormValid(false);
                return;
            }
        } else {
            if (codeError) {
                setIsCodeFormValid(false);
            }
        }

        setIsFormValid(true);
        setIsCodeFormValid(true);

        const login = async () => {
            let loggedIn = false;

            if (!twoFactForm) {
                await api.auth
                    .login({email, password})
                    .then(({data}) => {
                        if (data.success) {
                            auth.setError(null);
                            show2FAForm();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        if (error.response?.data?.message) {
                            auth.setError(error.response.data.message);
                        } else {
                            auth.setError(t('errors.server_error'));
                        }
                    });
            } else {
                await api.auth
                    .verifyCode({email, code})
                    .then(({data}) => {
                        auth.setToken(data.token);
                        auth.setError(null);
                        loggedIn = true;
                    })
                    .catch(error => {
                        console.error(error);
                        if (error.response?.data?.message) {
                            auth.setError(error.response.data.message);
                        } else {
                            auth.setError(t('errors.server_error'));
                        }
                    });
            }
            if (loggedIn) {
                auth.setIsLoaded(false);
                await api.auth
                    .getUsersCompany()
                    .then(({data: d}) => {
                        auth.setUserCompany(d.data);
                        history.push('/profile');
                    })
                    .catch(error => {
                        if (error.response) {
                            console.error(error.response);
                            if (error.response?.data?.message) {
                                auth.setError(error.response.data.message);
                            }
                            if (error.response?.data?.message === 'Token invalid') {
                                auth.setToken(null);
                            }
                        } else if (error.request) {
                            auth.setError(t('errors.server_error'));
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        console.error(error.config);
                    })
                    .finally(() => {
                        auth.setIsLoaded(true);
                    });
            }
        };

        login();
    };

    const onKeyPressHandler = e => {
        if (e.charCode === 13) {
            submitHandler();
        }
    };

    return (
        <form className={cl.form} id="login-form" onSubmit={e => e.preventDefault()} onKeyPress={e => onKeyPressHandler(e)}>
            {!twoFactForm && (
                <div>
                    <InputText
                        placeholder={t('placeholders.email')}
                        id="email"
                        icon="icon-e-mail"
                        value={email}
                        error={emailError}
                        callback={emailHandler}
                        isFormValid={isFormValid}
                    />
                    <InputPassword
                        placeholder={t('placeholders.password_1')}
                        id="password"
                        icon="icon-password"
                        value={password}
                        error={passError}
                        callback={passHandler}
                        isFormValid={isFormValid}
                    />
                    <ForgotPassBtn />
                    {auth.error && <p className={cl['error-message']}>{auth.error}</p>}
                </div>
            )}
            {twoFactForm && (
                <div>
                    <p className={cl['code-in-email-message']}>{t('login.code_in_email')}</p>
                    <InputText
                        placeholder={t('placeholders.code')}
                        id="code"
                        value={code}
                        error={codeError}
                        callback={codeHandler}
                        isFormValid={isCodeFormValid}
                    />
                    {auth.error && <p className={cl['error-message']}>{auth.error}</p>}
                </div>
            )}
            <div className={cl['btns-group']}>
                <div>
                    <Button type="submit" callback={submitHandler}>
                        {!twoFactForm ? t('login.btn_1') : t('login.submit_code')}
                    </Button>
                </div>
                {/* <div>
                    <Button type="button" icon={LinkedInIcon} onClick={e => e.preventDefault()}>
                        {t('login.btn_2')}
                    </Button>
                </div> */}
            </div>
        </form>
    );
};

export default LoginComponent;
