import React from "react";

import useAuth from "../../../../hooks/use-auth";

import { useTranslation } from "react-i18next";
import cl from "./ManageAccountBlock.module.scss";

const ManageAccountBlock = () => {
  const { logOut } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={cl.manageAccountBlock}>
      <button>{t("profile_page.change_password")}</button>
      <button>{t("profile_page.delete_account")}</button>
      <button onClick={logOut}>{t("profile_page.log_out")}</button>
    </div>
  );
};

export default ManageAccountBlock;
