import React from 'react';
import cl from './BlogFooter.module.scss';
import appStoreIcon from '../../../images/App_store_icon.png';
import LinkedinLogo from '../../../images/socialsIcons/linkedin.svg';
import TwitterLogo from '../../../images/socialsIcons/twitter.svg';
import FacebookLogo from '../../../images/socialsIcons/facebook.svg';

const BlogFooter = () => {
    return (
        <div className={cl.blogFooter}>
            <div className={cl.blogFooterLine}></div>
            <div className={cl.container}>
                <div className={cl.blogFooterWrapper}>
                    <div className={cl.blogFooterColumn}>
                        <p className={cl.blogFooterColumnTitle}>Rec Rout HR</p>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <span>Pricing</span>
                        </a>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <span>Contact us</span>
                        </a>
                    </div>
                    <div className={cl.blogFooterColumn}>
                        <p className={cl.blogFooterColumnTitle}>Support</p>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <span>Terms of use</span>
                        </a>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <span>Privacy policy</span>
                        </a>
                    </div>
                    <div className={cl.blogFooterColumn}>
                        <p className={cl.blogFooterColumnTitle}>Get in Touch</p>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <div className={cl.blogFooterSocialIconWrap}>
                                <img src={FacebookLogo} alt="" />
                            </div>
                            <span>Facebook</span>
                        </a>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <div className={cl.blogFooterSocialIconWrap}>
                                <img src={TwitterLogo} alt="" />
                            </div>
                            <span>Twitter</span>
                        </a>
                        <a href="/" className={cl.blogFooterColumnItem}>
                            <div className={cl.blogFooterSocialIconWrap}>
                                <img src={LinkedinLogo} alt="" />
                            </div>
                            <span>LinkedIn</span>
                        </a>
                    </div>
                    <div className={cl.blogFooterColumn}>
                        <img src={appStoreIcon} alt="" />
                        <p className={cl.copyright}>
                            © Copyright 2017, Rec Rout HR s.r.o.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogFooter;
