import { createContext } from "react";

export const AuthContext = createContext({
  isLoaded: false,
  user: null,
  userCompany: null,
  presentation: null,
  token: null,
  countries: null,
  industries: null,
  error: null,
  setIsLoaded: () => {},
  setUser: () => {},
  setUserCompany: () => {},
  setPresentation: () => {},
  setToken: () => {},
  setCountries: () => {},
  setIndustries: () => {},
  setError: () => {},
  logOut: () => {},
});
