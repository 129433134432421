import React from 'react';
import ProfileLayout from '../../layouts/profile/ProfileLayout';
import PresentationContainer from '../../components/profile/presentation-container/PresentationContainer';
import { PRESENTATION_TYPE } from '../../helpers/constants';

const Presentation = () => {
    return (
        <ProfileLayout page={'presentation'}>
            <PresentationContainer type={PRESENTATION_TYPE.PRIVATE} />
        </ProfileLayout>
    );
};

export default Presentation;
