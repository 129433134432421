export default function companySetInputsList(editMod, auth, t) {
  return [
    {
      id: 1,
      name: "name",
      type: "text",
      label: t("labels.company_name"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.company_name"),
    },
    {
      id: 2,
      name: "industry_id",
      type: "select",
      label: t("labels.industry"),
      readOnly: !editMod,
      options: auth.industries,
      required: true,
      errorMessage: t("errors.industry"),
    },
    {
      id: 3,
      name: "address",
      type: "text",
      label: t("labels.address"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.company_address"),
    },
    {
      id: 4,
      name: "zip",
      type: "number",
      label: t("labels.zip"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.zip"),
    },
    {
      id: 5,
      name: "country",
      type: "select",
      label: t("labels.country"),
      readOnly: !editMod,
      options: auth.countries,
      required: true,
      errorMessage: t("errors.country"),
    },
    {
      id: 6,
      name: "status",
      type: "text",
      label: t("labels.status"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.status"),
    },
    {
      id: 7,
      name: "vat_id",
      type: "number",
      label: t("labels.vat_id"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.vat"),
    },
    {
      id: 8,
      name: "tax_id",
      type: "number",
      label: t("labels.tax_id"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.tax"),
    },
    {
      id: 9,
      name: "contact_person",
      type: "text",
      label: t("labels.contact_person"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.contact_person"),
      transformValue: (value) => {
        return value.replace(/[^a-zа-яё\s]/gi, "");
      },
    },
    {
      id: 10,
      name: "contact_phone",
      type: "text",
      label: t("labels.mobile_number"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.contact_number"),
      transformValue: (value) => {
        var x = value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

        return !x[2]
          ? x[1]
          : "+" +
              x[1] +
              " " +
              x[2] +
              (x[3] ? " " + x[3] : "") +
              (x[4] ? " " + x[4] : "") +
              (x[5] ? " " + x[5] : "");
      },
      validator(value, setError) {
        if (value.length < 15) {
          setError(t("errors.contact_number"));
        } else setError("");
      },
    },
    {
      id: 11,
      name: "contact_email",
      type: "email",
      label: t("labels.e-mail"),
      readOnly: !editMod,
      required: true,
      errorMessage: t("errors.contact_email"),
      validator(value, setError) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(value).toLowerCase())) {
          setError(t("errors.contact_email"));
        } else {
          setError("");
        }
      },
    },
  ];
}
