import React from "react";

import brandImg from "../../../images/brand_image.png";
import Button from "../../UI/button/Button";

import { useTranslation } from "react-i18next";
import "../../../utils/i18next";

import cl from "./styles/Hero.module.scss";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className={cl.hero}>
      <div className={cl.content}>
        <h1 className={cl.title}>{t("home.title")}</h1>
        <p className={cl.text}>{t("home.description")}</p>
        <div className={cl.btnWr}>
          <Button type="router-link" href="/sign-up">
            {t("home.button_text")}
          </Button>
        </div>
      </div>
      <div className={cl.img}>
        <img alt="brand-img" src={brandImg} />
      </div>
    </section>
  );
};

export default Hero;
