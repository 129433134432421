import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import LoginContainer from '../components/landing/login/LoginContainer';
import PassCodeForm from '../components/landing/pass-code/PassCodeForm';
import { ReactComponent as Logo } from '../images/capa-hr-logo.svg';
import getUrlParams from '../utils/getUrlParams';
import api from '../services/api';

import useBreakpoints from '../hooks/use-breakpoints';

import styles from '../styles/page-styles/Login.module.scss';

const Login = () => {
    const breakpoint = useBreakpoints();

    const [enterPassCodeForm] = useState(false);
    const params = getUrlParams(useLocation().search);

    const activateProfile = async () => {
        await api.auth.activateAccount(params).catch((e) => {
            console.error(e);
        });
    };

    if (params.email !== undefined && params.token !== undefined) {
        activateProfile();
    }

    return (
        <div className={styles['login-page']}>
            {breakpoint !== 'mob' && (
                <div className={styles['login-aside']}>
                    <Link to="/" className={styles['logo-link']}>
                        <Logo />
                    </Link>
                </div>
            )}

            <div className={styles['login-form']}>
                {!enterPassCodeForm ? <LoginContainer /> : <PassCodeForm />}
            </div>
        </div>
    );
};

export default Login;
