import React, {useState, useMemo, useRef, useEffect} from 'react';

import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import SwiperCore, {EffectCreative} from 'swiper';
import 'swiper/swiper-bundle.min.css';

import Slide from './P-Slide';
import AppLink from '../app-link/AppLink';
import Video from '../../video/Video';

import useAuth from '../../../../hooks/use-auth';
import useModal from '../../../../hooks/use-modal';

import cl from './styles/PresentationSlider.module.scss';

SwiperCore.use([EffectCreative]);

const PresentationSlider = ({t, slidesIndexes = []}) => {
    const {presentation} = useAuth();
    const {show} = useModal();

    const [activeSlideIdx, setActiveSlideIdx] = useState(0);

    const swiperRef = useRef();

    useEffect(() => {
        const slider = swiperRef.current.swiper;
        slider.on('activeIndexChange', () => {
            setActiveSlideIdx(slider.activeIndex);
        });

        return () => {
            slider.off('activeIndexChange', () => {
                setActiveSlideIdx(slider.activeIndex);
            });
        };
    }, []);

    const slides = useMemo(() => {
        if (presentation && presentation.sections) {
            return presentation.sections;
        } else {
            return null;
        }
    }, [presentation]);

    const trimmedSlides = useMemo(() => {
        return slidesIndexes.length > 0 ? slides.filter((slide, index) => slidesIndexes.includes(index)) : slides;
    }, [slides, slidesIndexes]);

    const openVideo = (videoUrl, label, thumbnail) => {
        show(true, <Video url={videoUrl} label={label} thumbnail={thumbnail} />);
    };

    return (
        <div className={`${cl.presentationSlider} presentation-slider`}>
            <div className={cl.inner}>
                <Swiper
                    ref={swiperRef}
                    // spaceBetween={10}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    breakpoints={{
                        375: {},
                        768: {
                            spaceBetween: 15,
                            centeredSlides: true,
                        },
                    }}>
                    {trimmedSlides && trimmedSlides.length !== 0
                        ? trimmedSlides.map((slide, i) => {
                              return (
                                  <SwiperSlide
                                      key={`PresentaitonSlide-${i}`}
                                      className={`${activeSlideIdx < i ? `next next-${i - activeSlideIdx}` : ''}`}>
                                      <Slide slide={slide} handleClick={openVideo} />
                                  </SwiperSlide>
                              );
                          })
                        : [1, 2, 3].map((item, i) => {
                              return (
                                  <SwiperSlide key={`PresentaitonSlide-${i}`}>
                                      <div className={cl.blankFrame}>{i === 1 ? <AppLink /> : null}</div>
                                  </SwiperSlide>
                              );
                          })}
                </Swiper>
                <div className={cl.progressBar}>
                    <span>{trimmedSlides && trimmedSlides.length !== 0 ? `${activeSlideIdx + 1}` : '00'}</span>
                    <span>
                        {trimmedSlides && trimmedSlides.length !== 0 ? `${trimmedSlides.length}` : t('presentation.no_video')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PresentationSlider;
