import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import Tooltip from "../../general/tooltip/Tooltip";

import useBreakpoints from "../../../hooks/use-breakpoints";

import cl from "./TabsBlock.module.scss";

const TabsBlock = ({ tabs, t, page }) => {
  const breakpoint = useBreakpoints();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (tab) => {
    setActiveTab(tab);
    setIsOpen(true);
  };

  return (
    <div className={cl.tabsBlock}>
      <div className={cl.tabs}>
        {tabs &&
          tabs.length &&
          tabs.map(({ id, disabled, name }) => {
            const tab = () => (
              <div
                id={id}
                key={`presentation-settings-tab-${id}`}
                className={`${cl.tab} ${id === activeTab.id ? cl.active : ""} ${
                  disabled ? cl.disabled : ""
                } ${page === "profile" ? cl.tabSmall : ""}`}
                onClick={() => handleClick(tabs[id - 1])}
              >
                <h2 className={cl.tabName}>{name}</h2>
              </div>
            );
            if (disabled) {
              return (
                <Tooltip
                  key={`presentation-tooltip-${id}`}
                  text={t("tooltips.unverifiedCompaniesText")}
                  XIndentsOff
                >
                  {tab()}
                </Tooltip>
              );
            } else {
              return tab();
            }
          })}
      </div>
      {activeTab && breakpoint === "mob" && (
        <div className={`${cl.mobileContent} ${isOpen ? cl.active : ""}`}>
          <button className={cl.btnBack} onClick={() => setIsOpen(false)}>
            {activeTab.name}
          </button>
          {activeTab.components.length !== 0 &&
            activeTab.components.map((component) => (
              <div key={component.name}>{component()}</div>
            ))}
        </div>
      )}
      {activeTab && breakpoint !== "mob" && (
        <div className={cl.content}>
          <div>
            {activeTab.components.length !== 0 &&
              activeTab.components.map((component) => (
                <div key={component.name}>{component()}</div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

TabsBlock.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.string,
};

export default memo(TabsBlock);
