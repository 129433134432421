/* eslint-disable no-undef */
import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';

import ManageVideosSlider from '../manage-videos-slider/ManageVideosSlider';
import Loader from '../../../general/loader/Loader';

import useAuth from '../../../../hooks/use-auth';
import api from '../../../../services/api';

import {useTranslation} from 'react-i18next';
import '../../../../utils/i18next';
import {ReactComponent as IconCross} from '../../../../images/icons-cross.svg';
import {ReactComponent as StartIcon} from '../../../../images/start_video.svg';
import {ReactComponent as StopIcon} from '../../../../images/stop_icon.svg';

import cl from './ManageVideosContainer.module.scss';
import Button from '../../../UI/button/Button';

const ManageVideosContainer = () => {
    const [deepAR, setDeepAR] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [tabsNames, setTabsNames] = useState(['Všetky', 'Priestory', 'Team', 'Teambuilding']);
    const [recording, setRecording] = useState(false);
    const [recordModalOpen, setRecordModalOpen] = useState(false);
    const canvas = useRef(null);
    const {t} = useTranslation();

    const auth = useAuth();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isLoaded) {
            setTimeout(async () => {
                var obj = await deepar.initialize({
                    licenseKey: 'f0afab66f040a4ed79f866c3c9c971fb1acb6b905e2c59b3c66e7e7ea0a96cef8550b184d8f8265a',
                    previewElement: canvas.current,
                    effect: 'https://cdn.jsdelivr.net/npm/deepar/effects/aviators',
                });
                setDeepAR(obj);
            }, 50);
        }
    }, [isLoaded]);

    const startRecording = useCallback(async () => {
        if (deepAR && !recording) {
            setRecording(true);
            await deepAR.startVideoRecording();
        }
    }, [deepAR, recording]);

    const stopRecording = useCallback(async () => {
        if (deepAR && recording) {
            const video = await deepAR.finishVideoRecording();
            console.log(video);
            setRecording(false);
            createNewSection(video);
            setRecordModalOpen(false);
            setIsLoaded(false);
        }
    }, [deepAR, recording]);

    const createNewSection = video => {
        const presentationId = auth.presentation.presentation.id;

        // Vytvorenie sekcie
        const newSection = {
            label: 'New AR Section',
            video: video,
            presentation_id: presentationId,
            isCreatedNow: true,
            is_deleted: 0,
        };
        createPresentationSection(newSection);
    };

    const createPresentationSection = async presentationSection => {
        try {
            var formData = new FormData();
            formData.append('video', presentationSection.video, 'video');
            formData.append('label', presentationSection.label);
            formData.append('presentation_id', presentationSection.presentation_id);
            await api.auth.createPresentationSection(formData).then(res => {
                setIsLoaded(true);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const dataReq = useCallback(async () => {
        setIsLoaded(false);

        await api.auth
            .getPresentationSections()
            .then(({data: d}) => {
                auth.setPresentation(d.data);
            })
            .catch(error => {
                if (error.response) {
                    console.error(error.response);
                    if (error.response?.data?.message === 'Token invalid') {
                        auth.logOut();
                    }
                } else if (error.request) {
                    console.error(error.request);
                } else {
                    console.error('Error', error.message);
                }
                console.error(error.config);
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }, [auth]);

    useEffect(() => {
        if (auth.presentation) return;
        dataReq();

        // eslint-disable-next-line
    }, []);

    const tabs = useMemo(() => {
        return tabsNames.map((tab, index) => {
            return (
                <div className={activeTab === index ? cl.tabActive : cl.tabNonActive} onClick={() => setActiveTab(index)}>
                    <input
                        className={cl.tabInput}
                        type="text"
                        value={tab}
                        onChange={e => {
                            var arr = tabsNames.map((tab, i) => {
                                return index === i ? e.target.value : tab;
                            });
                            setTabsNames(arr);
                        }}
                    />
                </div>
            );
        });
    }, [activeTab, tabsNames]);

    if (isLoaded) {
        return (
            <div className={cl.manageVideosContainer}>
                <div className={cl.videosWrapper}>
                    <div className={cl.rightSide}>
                        <div className={cl.tabCont}>
                            {tabs}
                            <div
                                className={cl.plusIcon}
                                onClick={() => {
                                    setTabsNames([...tabsNames, 'New Section Name']);
                                }}>
                                +
                            </div>
                        </div>
                        {activeTab === 0 && <ManageVideosSlider t={t} />}
                        {activeTab === 1 && <ManageVideosSlider t={t} slidesIndexes={[0, 1]} />}
                        {activeTab === 2 && <ManageVideosSlider t={t} slidesIndexes={[2]} />}
                        {activeTab === 3 && <div></div>}
                    </div>

                    <div className={cl.newArVideo}>
                        <Button callback={() => setRecordModalOpen(true)}>+ New AR Video</Button>
                    </div>
                </div>
                <div
                    ref={canvas}
                    className={cl.arContainer}
                    id={'ar-screen'}
                    style={{display: recordModalOpen ? 'flex' : 'none'}}>
                    {!recording && (
                        <div onClick={startRecording} className={cl.record}>
                            <StartIcon />
                        </div>
                    )}
                    {recording && (
                        <div onClick={stopRecording} className={cl.stop}>
                            <StopIcon />
                        </div>
                    )}
                    <IconCross className={cl.close} onClick={() => setRecordModalOpen(false)} />
                </div>
            </div>
        );
    } else {
        return <Loader />;
    }
};

export default ManageVideosContainer;
