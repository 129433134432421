import React, {useState} from 'react';
import ProfileNavbar from '../../components/profile/profile-navbar/ProfileNavbar';

import brandImg from '../../images/profile-img.png';
import CircleCheck from '../../images/circle-check.svg';

import useBreakpoints from '../../hooks/use-breakpoints';

import cl from './ProfileLayout.module.scss';
import Chat from '../../components/general/chat/Chat';
import ArrowDownIcon from '../../images/arrow-down.svg';
import SharePresentation from '../../components/profile/presentation-blocks/share-presentation/SharePresentation';
import {useTranslation} from 'react-i18next';

const ProfileManageVideoLayout = ({children, title, description, page = ''}) => {
    const breakpoint = useBreakpoints();
    const {t} = useTranslation();
    const [showProjects, setShowProjects] = useState(false);

    const handleToggleShowProjectsPopup = () => setShowProjects(prev => !prev);

    const condition = breakpoint === 'mob' && page === 'profile';

    return (
        <div className={cl.profileLayout}>
            <ProfileNavbar img={page === 'profile' ? brandImg : null} page={page} />
            <div className={cl.container}>
                <p className={cl.status}>Draft</p>
                <div className={cl.flex_between}>
                    <div className={cl.title_flex_wrap}>
                        {title && !condition && <h1 className={cl.title}>{title}</h1>}
                        <img src={ArrowDownIcon} alt="" style={{cursor: 'pointer'}} onClick={handleToggleShowProjectsPopup} />
                        {showProjects ? (
                            <div className={cl.projectsPopup}>
                                <div className={cl.projectsPopup_item}>
                                    <div>
                                        <p className={cl.projectsPopup_item_title}>Marketing</p>
                                        <p>.../paltformhouse/marketing</p>
                                    </div>
                                    <img src={CircleCheck} alt="" />
                                </div>
                                {/*  */}
                                <div className={cl.projectsPopup_item}>
                                    <div>
                                        <p className={cl.projectsPopup_item_title}>Catering</p>
                                        <p>.../paltformhouse/catering</p>
                                    </div>
                                </div>
                                {/*  */}
                                <div className={cl.projectsPopup_item}>
                                    <div>
                                        <p className={cl.projectsPopup_item_title}>Events</p>
                                        <p className={cl.draftText}>Saved as a draft.</p>
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        ) : null}
                    </div>
                    <SharePresentation presentationData={{link_tag: '111'}} t={t} id={1} />
                </div>
                {description && !condition && <h1 className={cl.description}>{description}</h1>}
            </div>
            {children}
            <Chat />
        </div>
    );
};

export default ProfileManageVideoLayout;
