import React from "react";
import Navbar from "../components/landing/navbar/Navbar";
import Hero from "../components/landing/hero/Hero";
import Footer from "../components/landing/footer/Footer";

const Home = () => {
  return (
    <div className="home-page">
      <Navbar />
      <Hero />
      <Footer />
    </div>
  );
};

export default Home;
