import React, { useState } from "react";
import PropTypes from "prop-types";

import useAuth from "../../../../hooks/use-auth";
// eslint-disable-next-line
import api from "../../../../services/api";

import cl from "./styles/ManageVideosSlider.module.scss";

const SlideInputLabel = ({ label, id, presentation_id, is_deleted }) => {
  // eslint-disable-next-line
  const { logOut } = useAuth();

  const [value, setValue] = useState(label);

  const [focused, setFocused] = useState(false);
  // eslint-disable-next-line
  const [videoName, setVideoName] = useState(label);
  // eslint-disable-next-line
  const [error, setError] = useState(false);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const changeLabelText = async () => {
    if (value === videoName) return;

    await api.auth
      .updatePresentationSection({
        id,
        label: value ? value : "No name",
        presentation_id,
        is_deleted,
      })
      .then(() => {
        setVideoName(value);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
          if (error.response?.data?.message === "Token invalid") {
            logOut();
          }
        }
      });
  };

  const blurHandler = async () => {
    setFocused(false);
    changeLabelText();
  };

  return (
    <div className={cl.slideInputLabel}>
      {error && <p className={cl.error}>Error! Please try again later.</p>}
      <input
        type="text"
        value={value}
        className={`${cl.input} ${focused ? cl.focused : ""}`}
        onChange={onChangeHandler}
        onFocus={() => setFocused(true)}
        onBlur={blurHandler}
      />
    </div>
  );
};

SlideInputLabel.propTypes = {
  value: PropTypes.string,
};

export default SlideInputLabel;
