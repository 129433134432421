import React from 'react';
import Button from '../../../../components/UI/button/Button';
import ShareIcon from '../../../../images/icons-share.svg';

import cl from './SharePresentation.module.scss';
import useModal from '../../../../hooks/use-modal';
import SharePresentationModal from '../../../../components/profile/pop-up-blocks/share-presentation/SharePresentationModal';
import api from '../../../../services/api';

const SharePresentation = ({ t, id, presentationData }) => {
    const { setModalContextData, hide } = useModal();

    const handleSharePresentation = async () => {
        setModalContextData({
            isOpen: true,
            context: (
                <SharePresentationModal
                    closeModal={hide}
                    t={t}
                    presentationData={presentationData}
                />
            ),
        });

        await api.auth.incrementPresentationShares({ id }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            {presentationData && presentationData.link_tag ? (
                <div className={cl.sharePresentation}>
                    <Button icon={ShareIcon} callback={handleSharePresentation}>
                        {t('buttons.share_presentation')}
                    </Button>
                </div>
            ) : null}
        </>
    );
};

export default SharePresentation;
