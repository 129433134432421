import React from 'react';
import {Helmet} from 'react-helmet';

const Chat = () => {
    return (
        <Helmet>
            <script>
                {`window.__be = window.__be || {};
     window.__be.id = "64b67ec1b99a69000763342a";
    (function() {
        var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true;
        be.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.chatbot.com/widget/plugin.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
     })();`}
            </script>
        </Helmet>
    );
};

export default Chat;
