import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import gsap from 'gsap';
import {Timeline} from 'gsap/gsap-core';
import {ReactComponent as Logo} from '../../../images/capa-hr-logo.svg';
import BurgerIcon from '../../../images/burger.svg';
import {useTranslation} from 'react-i18next';
import '../../../utils/i18next';
import cl from './Navbar.module.scss';

import IconSearch from '../../../images/icon-search.svg';
import {checkIfMobile} from '../../../helpers/checkIfMobile';

const Navbar = ({opt, searchText, search}) => {
    const logoRef = useRef(null);
    const logoLineRef = useRef(null);
    const {t, i18n} = useTranslation();

    const onLanguageChange = lang => i18n.changeLanguage(lang);
    const isMobile = checkIfMobile();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const handleToggleMenu = () => setShowMobileMenu(prev => !prev);

    useEffect(() => {
        gsap.set(logoRef.current, {x: '-115%'});
        gsap.set(logoLineRef.current, {height: '100%'});
        var lines = new Timeline()
            .to(logoRef.current, {x: '0%', duration: 1}, 0.5)
            .to(logoLineRef.current, {height: '0px', duration: 0.2}, 1.3);
    }, []);

    return (
        <div className={`${cl.navbar} ${opt === 'blackText' ? cl.blackText : ''}`}>
            <div>
                <div className={cl.logoWrapper}>
                    <div ref={logoLineRef} className={cl.logoLine}></div>
                    <Link ref={logoRef} className={cl.logoLink} to="/">
                        <Logo />
                    </Link>
                </div>
                {search ? (
                    <div className={`${cl.searchWrap} hide-mobile`}>
                        <img src={IconSearch} alt="" style={{marginRight: '26px'}} />
                        <input className={cl.searchWrapInput} placeholder={t('search')} value={searchText} onChange={search} />
                        {searchText ? (
                            <div className={cl.searchResults}>
                                <div className={cl.searchResultItem}>
                                    <div className={cl.searchResultImageWrap}></div>
                                    <div>
                                        <p className={cl.searchResultTitle}>Global Talent Mobility Research in a Pandemic Era</p>
                                        <p>26.04.22</p>
                                    </div>
                                </div>
                                <div className={cl.searchResultItem}>
                                    <div className={cl.searchResultImageWrap}></div>
                                    <div>
                                        <p className={cl.searchResultTitle}>Global Talent Mobility Research in a Pandemic Era</p>
                                        <p>26.04.22</p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div className={cl.wrapper}>
                <nav className={cl.links}>
                    <Link className={cl.link} to="/blog">
                        {t('links.blog')}
                    </Link>
                    {/* <Link className={cl.link} to="/pricing">
                        {t('links.pricing')}
                    </Link> */}
                    <Link className={cl.link} to="/login">
                        {t('links.login')}
                    </Link>
                    <Link className={cl.link} to="/sign-up">
                        {t('links.sign-up')}
                    </Link>
                </nav>
                <div className={cl.localization}>
                    <button className={`${i18n.language === 'en' ? cl.active : ''}`} onClick={() => onLanguageChange('en')}>
                        en
                    </button>
                    <button className={`${i18n.language === 'sk' ? cl.active : ''}`} onClick={() => onLanguageChange('sk')}>
                        sk
                    </button>
                </div>
                <div className={cl.burger} onClick={handleToggleMenu}>
                    <img style={{cursor: 'pointer'}} src={BurgerIcon} alt="" />
                </div>
            </div>
            {showMobileMenu && isMobile ? (
                <div className={cl.mobileMenuWrapper}>
                    <div className={cl.mobileMenuBackground}></div>
                    <div className={cl.mobileMenuContent}>
                        <Link className={cl.mobileMenuItem} to="/blog">
                            {t('links.blog')}
                        </Link>
                        {/* <Link className={cl.mobileMenuItem} to="/pricing">
                            {t('links.pricing')}
                        </Link> */}
                        <Link className={cl.mobileMenuItem} to="/login">
                            {t('links.login')}
                        </Link>
                        <Link className={cl.mobileMenuItem} to="/sign-up">
                            {t('links.sign-up')}
                        </Link>
                        <p onClick={handleToggleMenu} className={cl.mobileMenuItem}>
                            {' '}
                            x
                        </p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Navbar;
