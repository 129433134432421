import React, { useState, useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { useOutsideClick } from "../../../hooks/use-outside-click";
import { ReactComponent as ArrowDown } from "../../../images/arrow-down.svg";

import cl from "./FormInputSelect.module.scss";

const FormInputSelect = ({
  label,
  options,
  value,
  name,
  readOnly,
  setState,
}) => {
  const [active, setActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const myInputs = useRef(null);

  useEffect(() => {
    if (options && options.length !== 0) {
      options.forEach((opt, i) => {
        if (opt.value.toString() === value.toString()) {
          setSelectedIndex(i);
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [options, value]);

  const closeInputList = () => {
    setActive(false);
  };

  useOutsideClick(myInputs, closeInputList);

  const classes = `${cl.inputSelectWr} ${readOnly ? cl.readOnly : ""} ${
    active ? cl.active : ""
  }`;

  const handleClick = (e, i) => {
    setState((prev) => ({ ...prev, [name]: e.target.dataset.value }));
    setSelectedIndex(i);
  };

  return (
    <div className={classes} ref={myInputs} tabIndex="0">
      <label>{label}</label>
      <div className={cl.value} onClick={() => setActive((prev) => !prev)}>
        <span>
          {options && (selectedIndex || selectedIndex === 0)
            ? options[selectedIndex].name
            : ""}
        </span>
        <ArrowDown />
      </div>
      {options && (
        <ul className={cl.valuesList}>
          <Scrollbars
            renderThumbVertical={(props) => (
              <div {...props} className={cl.thumbVertical} />
            )}
          >
            {options.map((opt, i) => {
              return (
                <li
                  data-value={opt.value}
                  key={opt.value}
                  className={`${cl.item} ${
                    value && value.toString() === options[i].value.toString()
                      ? cl.active
                      : ""
                  }`}
                  onClick={(e) => handleClick(e, i)}
                >
                  {opt.name}
                </li>
              );
            })}
          </Scrollbars>
        </ul>
      )}
    </div>
  );
};

export default FormInputSelect;
