import React, { useState, useEffect } from "react";

import cl from "./FormInput.module.scss";

const FormInput = ({
  label,
  errorMessage,
  transformValue = null,
  value,
  validator = null,
  readOnly,
  setState,
  ...inputProps
}) => {
  const [focused, setFocused] = useState(false);
  const [err, setErr] = useState("");

  const handleFocus = (e) => {
    setFocused(true);
  };

  useEffect(() => {
    readOnly && setErr("");
  }, [readOnly]);

  const onChange = (e) => {
    let value = e.target.value;
    if (transformValue) value = transformValue(value);

    validator && validator(value, setErr);

    setState((prev) => ({ ...prev, [e.target.name]: value }));
  };

  return (
    <div className={cl.formInput}>
      <label>{label}</label>
      <input
        {...inputProps}
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={handleFocus}
        focused={focused.toString()}
      />
      {!err && <span className={cl.error}>{errorMessage}</span>}
      {err && <span className={cl.customError}>{err}</span>}
    </div>
  );
};

export default FormInput;
